import '../../service/domain/http.js';
import '../../service/rx/notification$.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q', 'notification$'];

  function controller(_http, $q, _notification$) {
    return {
      item(options) {
        const alias = {
          name: 'tournamentItem',
          param: options.name,
        };
        return _http.getData(alias, options.params, options.cache);
      },

      list(options) {
        const defer = $q.defer();
          const alias = 'tournamentList';
          const config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      fastTournamentList(options) {
        const defer = $q.defer();
          const alias = 'fastTournamentList';
          const config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      fastTournamentHistory(options) {
        const defer = $q.defer();
          const alias = 'fastTournamentHistory';
          const config = {
            params: options,
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      fastTournamentSubscribe(tournamentEventId) {
        const defer = $q.defer();
          const alias = 'fastTournamentSubscribe';
          const data = {
            tournamentEventId,
          };
          const config = {
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      fastTournamentGetLeaderboard(tournamentEventId, options) {
        const defer = $q.defer();
          const alias = {
            name: 'fastTournamentGetLeaderboard',
            param: tournamentEventId,
          };
          const config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      leagueClaimReward(data) {
        const defer = $q.defer();
          const alias = 'leagueClaimReward';
          const config = {
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
    };
  }

  app.factory('tournament', controller);
})();
