import '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    const o = {
      object: {},
      collection,
    };

    function collection(options) {
      const defer = $q.defer();
        const alias = 'providerList';
        const config = {
          params: options || {},
          cache: true,
          meta: {
            type: 'api',
            alias,
          },
        };

      config.params.count = options && options.count ? options.count : 1000;

      _http.get(alias, config).then(
        (answer) => {
          o.object = answer.data.result.toObject('name');
          o.object.list = answer.data.result;
          o.object.total_count = answer.data.result.length;
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        }
      );

      return defer.promise;
    }

    return o;
  }

  app.factory('brand', controller);
})();
