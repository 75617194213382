(function () {
  'use strict';

  const directive = { name: 'lindaFirstView' };
  controller.$inject = ['$window'];
  function controller($window) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs.lindaFirstView);

      const firstViewStorage = {
        [config.id]: true,
      };

      const parsedValue = JSON.parse($window.localStorage.getItem('first-view'));

      if (parsedValue === null || parsedValue[config.id] === false) {
        element[0].classList.add('linda-shown');
        $window.localStorage.setItem('first-view', JSON.stringify(firstViewStorage));
      } else {
        const result = Object.assign(parsedValue, firstViewStorage);
        $window.localStorage.setItem('first-view', JSON.stringify(result));
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
