app.directive('datepicker', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attrs, ngModelCtrl) {
      import('jquery').then(($) => {
        // eslint-disable-next-line angular/window-service
        window.jQuery = $;
        import('jquery-ui/ui/widgets/datepicker').then(() => {
          const updateModel = function (dateText) {
            scope.$apply(function () {
              ngModelCtrl.$setViewValue(dateText);
            });
          };

          const datepickerBody = attrs.datepickerBody || '.datepicker-body';

          const options = {
            onClose: function () {
              elem.removeClass('datepicker-open');
            },

            dateFormat: 'yy-mm-dd',

            onSelect: function (dateText) {
              updateModel(dateText);
            },

            beforeShow: function (textbox, instance) {
              elem.addClass('datepicker-open');
              $(elem).parent().find(datepickerBody).append($('#ui-datepicker-div'));
            },
            changeMonth: true,
            changeYear: true,
            yearRange: attrs.yearRange || '-100:-18',
            defaultDate: attrs.defaultDate || '1998-01-01',
          };
          if (attrs.minDate) options.minDate = attrs.minDate;
          $(elem).datepicker(options);
        });
      });
    },
  };
});
