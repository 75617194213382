import '../../service/domain/http.js';
import '../../service/domain/user.js';
import '../../service/rx/ws$.js';
import '../../service/configs/ws-events.js';
import '../../service/domain/account.js';
import {filter} from "rxjs/operators";

(function () {
  app.factory('achievement', controller);
  controller.$inject = ['http', '$q', 'user', 'ws$', 'wsEvents', 'account'];

  function controller(_http, $q, _user, _ws$, _wsEvents, _account) {
    _ws$.pipe(filter(({ event }) => event === _wsEvents.achieve)).subscribe(() => {
      _account.info();
    });
    return {
      take(data) {
        const defer = $q.defer();
          const alias = 'achievementTake';
          const config = { meta: { alias } };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      lastEarned(options) {
        const defer = $q.defer();
          const alias = 'achievementLastEarned';
          const config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };
        _http.get(alias, config).then(
          (a) => {
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );
        return defer.promise;
      },

      inProgress(options) {
        const defer = $q.defer();
          const alias = 'achievementInProgress';
          const config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };
        _http.get(alias, config).then(
          (a) => {
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );
        return defer.promise;
      },

      item(params) {
        const defer = $q.defer();
        const alias = 'achievementItem';
        const config = {
          params,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http
          .get(alias, config)
          .then(({ data }) => defer.resolve(data))
          .catch(({ data }) => defer.reject(data));

        return defer.promise;
      },

      list(options) {
        const defer = $q.defer();
          const alias = 'achievements';
          const config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };
        _http.get(alias, config).then(
          (a) => {
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );
        return defer.promise;
      },
    };
  }
})();
