import {fromEvent} from 'rxjs';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return fromEvent(window, 'click');
  }

  app.factory('click$', controller);
})();
