import '../../service/domain/sport.js';
import '../../service/configs/config.js';
import '../../service/rx/system$.js';
import '../../service/domain/user.js';
import '../../service/domain/state.js';
import { Subject, fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  const name = 'lindaSportRace';

  controller.$inject = ['$state', 'sport', 'config', 'system$', 'user', 'state'];

  function controller($state, _sport, _config, system$, _user, _state) {
    function link($scope, $element) {
      const destroy$ = new Subject();

      const message$ = fromEvent(window, 'message');

      $scope.preloader = false;

      init();

      message$
        .pipe(
          takeUntil(destroy$),
          filter((message) => message.origin === 'https://bet-j.com' || message.origin === 'https://latrobet28.com')
        )
        .subscribe((message) => {
          let horseAlias = message.data;
          const urlPrefix = 'URL:';
          if (horseAlias.startsWith(urlPrefix)) {
            horseAlias = horseAlias.split(':')[1];
          }
          if (message.data) {
            _state.goto(horseAlias);
          }
        });

      function init() {
        launchFrame();

        if (!_user.status) {
          system$
            .pipe(
              takeUntil(destroy$),
              filter((event) => event.action === 'statusChanged')
            )
            .subscribe(launchFrame);
        }
      }

      function launchFrame() {
        getLaunchCode()
          .then(appendFrame)
          .catch(() => {
            $scope.preloader = false;
          });
      }

      function getLaunchCode() {
        return new Promise((resolve, reject) => {
          $scope.preloader = true;

          const body = {
            code: 'sport-latrobet',
          };

          _sport
            .launch(body)
            .then(({ result }) => {
              resolve(result);
            })
            .catch((err) => reject(err));
        });
      }

      function appendFrame(settings) {
        const frame = document.createElement('iframe');
        const horseCategory = $state.params.category;
        const url = new URL(settings.url);

        const horseRacingRedirects = {
          horseRacing: '/Home/raceMenu?sportId=9001',
          harness: '/Home/raceMenu?sportId=9002',
          greyhound: '/Home/raceMenu?sportId=9003',
          nextToJump: '/Home/NextToJump?sportId=9004',
        };
        url.searchParams.append('redirect', horseRacingRedirects[horseCategory]);

        frame.classList.add('linda-racing-frame');
        frame.src = url.href;
        frame.style.border = '0';

        frame.onload = () => {
          $scope.preloader = false;
        };
        frame.onerror = () => {
          $scope.preloader = false;
        };

        if (_config.device.mobile) {
          frame.style.height = (settings.mobile && settings.mobile.height) || '100%';
          frame.style.width = (settings.mobile && settings.mobile.width) || '100%';
        } else {
          frame.style.height = (settings.desktop && settings.desktop.height) || '100%';
          frame.style.width = (settings.desktop && settings.desktop.width) || '100%';
        }

        $element[0].innerHTML = '';
        $element.append(frame);

        return frame;
      }

      $scope.$on('$destroy', () => {
        destroy$.next();
        destroy$.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
