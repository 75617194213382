(function () {
  'use strict';

  app.directive('lindaValidateBirthday', controller);

  function controller() {
    function link(scope, element, attrs, ctrl) {
      ctrl.$parsers.push((value) => validator(value));

      function validator(value) {
        const minAge = 18;
        const maxAge = 100;

        const birthdayTimestamp = Date.parse(value);
        const currentDateTimestamp = new Date().getTime();

        const userAgeTimestamp = currentDateTimestamp - birthdayTimestamp; /* вік юзера в мілісекундах */
        const userAge = Math.floor(userAgeTimestamp / 3153.6e7); /* мілісекунди перевести в роки */

        const conditions = [(userAge >= minAge && userAge <= maxAge) || !value];

        ctrl.$setValidity('over18', conditions[0]);

        return conditions.every((i) => i === true) ? value : undefined;
      }
    }

    return {
      restrict: 'A',
      link,
      require: 'ngModel',
    };
  }
})();
