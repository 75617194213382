import '../../service/analytics/analytics.js';
import '../../service/domain/user.js';
export const kreditzAnalytics = 'kreditzAnalytics';

class KreditzAnalytics {
  static $inject = ['analytics', 'user'];

  constructor(analytics, _user) {
    this.analytics = analytics;
    this.user = _user;
  }

  addEventData(data) {
    this.analytics.sendToDataLayer({
      ...data,
      visitor_id: this.user.profile.account_id.toString(),
      eventTimestamp: Date.now().toString(),
      eventDate: new Date().toISOString(),
    });

  }

  onOpenButtonClicked() {
    this.addEventData({
      eventType: 'manual',
      event: 'kycUploadDocumentsOpenButton',
    });
  }

  onFrameOpened() {
    this.addEventData({
      eventType: 'auto',
      event: 'kycUploadDocumentsFrameOpened',
    });
  }

  onCloseButtonClicked() {
    this.addEventData({
      eventType: 'manual',
      event: 'kycUploadDocumentsCloseButton',
    });
  }

  onUploadDocumentsFinished() {
    this.addEventData({
      eventType: 'auto',
      event: 'kycUploadDocumentsFinished',
    });
  }

}

app.service(kreditzAnalytics, KreditzAnalytics);
