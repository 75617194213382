import '../../service/gamification/wall-of-wins.js'
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

(function () {
  'use strict';
  app.factory('wallOfWins$', () => new Subject());
})();

(function () {
  'use strict';

  const directive = { name: 'wallOfWinsRandom' };

  controller.$inject = ['$interval', 'wallOfWins$'];

  function controller($interval, _wallOfWins$) {
    function link(scope, elem, attrs) {
      const destroy$ = new Subject();
      const config = scope.$eval(attrs[directive.name]);

      let queue = [];

      const interval = $interval(() => {
        if (config.min === config.max) {
          return false;
        }
        const random = generate();
        _wallOfWins$.next({ event: 'new', data: { index: random } });
      }, config.intervalTime);

      _wallOfWins$.pipe(
        takeUntil(destroy$).subscribe((options) => {
          switch (options.event) {
            case 'remove':
              queue = queue.filter((i) => i !== options.data.index);
              break;
            case 'random_diapason':
              config.min = options.data.min;
              config.max = options.data.max;
          }
      }));

      function generate() {
        let randomValue = -1;
        if (queue.length < (config.max - config.min) / 4) {
          while (!isAllowed(randomValue)) {
            randomValue = Math.floor(Math.random() * (config.max - config.min + 1) + config.min);
          }
          queue.push(randomValue);
        }
        return randomValue;
      }

      function isAllowed(randomValue) {
        if (randomValue < 0 || queue.includes(randomValue)) {
          return false;
        }
        let allow = true;
        queue.forEach((i) => {
          if (
            i === randomValue + 1 ||
            i === randomValue - 1 ||
            i === randomValue + config.rows ||
            i === randomValue - config.rows
          ) {
            allow = false;
          }
        });
        return allow;
      }

      scope.$on('$destroy', () => {
        destroy$.next();
        destroy$.unsubscribe();
        $interval.cancel(interval);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
