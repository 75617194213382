import '../service/domain/http.js';
import '../service/rx/system$.js';
import '../service/domain/user.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$window', 'http', '$q', 'system$', 'user'];

  function controller($window, _http, $q, _system$, _user) {
    const globalCdn = '/joxi';
    const symplifyScriptId = 'symplify-script';
    const stylesSrc = `${globalCdn}/js/symplify/webpush.css`;
    const scriptSrc = 'https://d3mi6d1ao3fzsg.cloudfront.net/webpush/1/webpush.min.js';
    const serviceWorkerSrc = '/symplify_sw.js';

    const getUserHash = () => {
      const defer = $q.defer();
        const alias = 'userSymplifyHash';
        const config = {
          meta: { alias },
        };

      _http
        .get(alias, config)
        .then(({ data }) => {
          defer.resolve(data);
        })
        .catch((error) => {
          defer.reject(error);
        });

      return defer.promise;
    };

    const createElement = (tag, config) => {
      const element = document.createElement(tag);
      // eslint-disable-next-line guard-for-in
      for (const key in config) {
        element.setAttribute(key, config[key]);
      }
      return element;
    };

    const appendStylesLink = () => {
      const attrs = {
        rel: 'stylesheet',
        type: 'text/css',
        href: stylesSrc,
      };

      const link = createElement('link', attrs);
      document.head.appendChild(link);
    };

    const appendScript = (userHash) => {
      const attrs = {
        type: 'application/javascript',
        id: symplifyScriptId,
        src: scriptSrc,
        'data-enable-api': 'true',
        'data-notifications': 'true',
        'data-service-worker': serviceWorkerSrc,
        'data-user-hash': userHash,
        async: 'true',
      };
      const target = document.scripts[document.scripts.length - 1];
      const scriptSymplify = createElement('script', attrs);
      target.parentElement.insertBefore(scriptSymplify, target.nextSibling);
    };

    const removeScript = () => {
      const scriptSymplify = document.getElementById(symplifyScriptId);
      scriptSymplify?.remove();
    };

    const start = () => {
      getUserHash()
        .then(({ result }) => {
          appendScript(result.userHash);
        })
        .catch(() => {})
        .finally(() => {});
    };

    const makeSubscriptions = () => {
      _system$
        .pipe(filter(({ action }) => action === 'login'))
        .subscribe(() => {
          start();
        });

      _system$
        .pipe(filter(({ action }) => action === 'logout'))
        .subscribe(() => {
          if ($window.Symplify) {
            Symplify.WebPush.hide();
          }
          removeScript();
        });
    };

    const init = () => {
      appendStylesLink();
      if (_user.status) {
        start();
      }
      makeSubscriptions();
    };

    return {
      init,
    };
  }

  app.factory('symplify', controller);
})();
