(function () {
  'use strict';

  removeHashFromURL.$inject = ['$window'];

  function removeHashFromURL($window) {
    return function () {
      const noHashURL = $window.location.href.replace(/#.*$/, '');
      $window.history.replaceState('', '', noHashURL);
    };
  }

  app.factory('removeHashFromURL', removeHashFromURL);
})();
