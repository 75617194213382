import '../../service/configs/config.js';

(function () {
  'use strict';

  app.directive('head', controller);

  controller.$inject = ['config', '$timeout'];

  function controller(_config, $timeout) {
    function link(scope, element, attrs) {
      $timeout(() => {
        _config.customScripts.forEach((item) => {
          const element = document.createElement('script');
          element.src = `${item}?build=${_config.build}`;
          element.async = false;
          document.head.appendChild(element);
        });
      }, 1000);
    }

    return {
      restrict: 'E',
      // scope: true,
      link: link,
    };
  }
})();
