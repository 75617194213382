import '../../service/domain/user.js'
import '../../service/rx/user$.js'
import '../../service/configs/trustly.js'
import '../../service/configs/config.js'
import '../../service/domain/remembered.js'
(function () {
  'use strict';

  app.directive('body', controller);

  const className = {
    user: 'linda-has-user',
    pnp: 'pay-n-play',
    rememberedLogin: 'linda-has-remembered-login',
    rtlLang: 'rtl-lang',
  };

  controller.$inject = ['user', 'user$', '$compile', 'trustly.config', 'config', 'remembered', '$window'];

  function controller(_user, _user$, $compile, _trustly_config, _config, _remembered, $window) {
    function link(scope, element, attrs) {
      if (!$window.localStorage.getItem('gdpr-cookies')) {
        element.append($compile('<linda-gdpr-cookies />')(scope));
      }

      _user$.subscribe((m) => {
        element.toggleClass(className.user, _user.status);
        if (_user.status) {
          element.addClass(className.rememberedLogin);
        }
      });

      element.toggleClass(className.pnp, _trustly_config.enabled);

      element.toggleClass(className.rememberedLogin, Boolean(_remembered.login));

      element.toggleClass(className.rtlLang, _config.currentLang === 'ar');
    }

    return {
      restrict: 'E',
      link,
    };
  }
})();
