import '../../../service/domain/popup.js'
import '../../../service/domain/user.js'
(function () {
  controller.$inject = ['$scope', 'popup', 'user'];

  function controller($scope, _popup, _user) {
    $scope.item = {};

    $scope.buy = (confirm) => {
      if (_user.profile.coins >= $scope.item.price) {
        _popup.open({
          name: confirm,
          content: $scope.item,
        });
        return;
      }
      if (_user.profile.coins === 0) {
        _popup.open({ name: 'shop-deposit', content: $scope.item });
        return;
      }
      _popup.open({ name: 'shop-play', content: $scope.item });
    };

    function getMinOfferPrice(array) {
      return Math.min(...array.map((offer) => offer.price));
    }

    $scope.buyFreespins = (confirm) => {
      if (_user.profile.coins >= getMinOfferPrice($scope.item.offers)) {
        _popup.open({
          name: confirm,
          content: $scope.item,
        });
        return;
      }
      if (_user.profile.balance === 0) {
        _popup.open({ name: 'shop-deposit', content: $scope.item });
        return;
      }
      _popup.open({ name: 'shop-play', content: $scope.item });
    };

    $scope.selectOffer = (offer, confirmation = false) => {
      Object.assign($scope.item, offer);

      if(confirmation) {
        _popup.open({ name:'shop-confirm-fs', content: $scope.item });
      }
    };

    this.$onChanges = () => {
      $scope.item = this.item;
    };
  }

  new Controller('shopFreespinItem', controller, {
    item: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanShopFreespinItem${i}`, 'shopFreespinItem');
  });
})();
