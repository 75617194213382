import '../../service/rx/user$.js';
import '../../service/domain/game.js';
import '../../service/domain/user.js';
import '../../service/configs/config.js';
import { filter, first } from 'rxjs/operators';
(function () {
  'use strict';

  const directive = { name: 'crabSportWidget' };

  controller.$inject = ['user$', 'game', 'user', 'config', '$window'];

  function controller(_user$, _game, _user, _config, $window) {
    const link = (scope, element) => {
      const postData = {
        crabOptionsReady: false,
        launch_options: {
          brand: _config.siteName,
          locale: _config.currentLang,
          brandFeature: _config.feature,
        },
      };

      let subscription = null;

      if (_user.status === true) {
        run();
      } else {
        subscription = _user$
          .pipe(
            filter((data) => data.status === true),
            first()
          )
          .subscribe(() => {
            run();
          });
      }

      function run() {
        _game
          .launch({ code: 'cash-crab', mode: 'real' })
          .then((data) => {
            postData.crabOptionsReady = true;
            Object.assign(postData.launch_options, data.result.launch_options);
            $window.addEventListener('message', handleMessage, true);
          })
          .catch(() => {})
          .finally(() => {});
      }

      function handleMessage(e) {
        if (e.data?.crab?.ready === true && postData.crabOptionsReady === true) {
          e.source.postMessage(postData, '*');
        }
      }

      scope.$on('$destroy', () => {
        $window.removeEventListener('message', handleMessage, true);
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    };

    return { link };
  }

  app.directive(directive.name, controller);
})();
