import '../../service/verification-alert.js';
import '../../service/rx/system$.js';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

const componentName = 'surveyAlert';

class SurveyAlert {
  static $inject = ['$scope', '$document', 'verificationAlert', 'system$' ];
  constructor($scope, $document, verificationAlert, _system$) {
    const destroy$ = new Subject();
    const PARAMS = {
      localStorageKey: 'show-verification-bar',
      bodyHasNotificationClass: 'has-survey',
    };

    function changeStatus() {
      const bodyClassList = $document[0].body.classList;
      $scope.activeAlert = verificationAlert.getActiveAlert(PARAMS);
      bodyClassList.remove(PARAMS.bodyHasNotificationClass);
      if (hasActiveAlert()) {
        bodyClassList.add(PARAMS.bodyHasNotificationClass);
        $scope.activeAlert = 'SURVEY';
      }
    }

    function hasActiveAlert() {
      return verificationAlert.getActiveAlert(PARAMS) === 'SURVEY';
    }

    changeStatus();

    _system$
      .pipe(
        takeUntil(destroy$),
        filter(({ action }) => action === 'statusChanged'),
      )
      .subscribe(changeStatus);

    $scope.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }
}

app.component(componentName, {
  controller: SurveyAlert,
  template: app.getTU(componentName),
});
