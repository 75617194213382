import '../service/rx/scroll$.js';
(function () {
  'use strict';

  const directive = { name: 'updateClassOnScrollTo' };

  // TODO: 'DRAFT:', directive.name

  controller.$inject = ['scroll$'];

  function controller(_scroll$) {
    function link(scope, elem, attrs) {
      const config = scope.$eval(attrs[directive.name]);
      const element = elem[0];

      let configBreakline = 0;
      let destElement = {};
      let destElementPositionY = 0;
      let isUnderBreakline = false;

      const actionOptions = {
        add: () => {
          if (isUnderBreakline) return element.classList.add(config.class);
        },
        remove: () => {
          if (isUnderBreakline) return element.classList.remove(config.class);
        },
        toggle: () => (isUnderBreakline ? element.classList.add(config.class) : element.classList.remove(config.class)),
      };

      _scroll$.debounceTime(100).subscribe(() => {
        const breaklineOptions = {
          viewportBottom: window.innerHeight,
          documentBottom: document.documentElement.offsetHeight - window.innerHeight,
        };

        try {
          destElement = document.querySelector(config.breakline);
          destElementPositionY =
            Math.ceil(destElement.getBoundingClientRect().top) + Math.ceil(window.scrollY) - window.innerHeight;
        } catch {
          destElement = null;
          destElementPositionY = null;
        }

        configBreakline = breaklineOptions[config.breakline] || destElementPositionY || Number(config.breakline);
        isUnderBreakline = Math.ceil(window.scrollY) >= configBreakline;

        if (!(config.action in actionOptions) || !configBreakline) {
          console.warn("unacceptable config field value in 'update-class-on-scroll-to' directive");
          return;
        }

        return actionOptions[config.action]();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
