import '../../service/domain/user.js';
import '../../service/rx/system$.js';
import '../../service/domain/documentVerification.js';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators'
(function () {
  'use strict';

  const directive = { name: 'dvsNotificationNotVerified' };

  controller.$inject = ['user', 'system$', 'verificationInfo$', '$window'];

  function controller(_user, _system$, _verificationInfo$, $window) {
    function link($scope, $element) {
      const destroy$ = new Subject();

      function changeVerificationStatus() {
        const verificationStatus = _user.profile.verificationStatus;
        const verificationStatusGroups = _user.profile.groups;
        $scope.isVerificationBlocked = false;

        if (verificationStatus === 'REQUIRES_VERIFICATION') {
          if (verificationStatusGroups.length) {
            $scope.isVerificationBlocked = true;
          }
          $window.localStorage.setItem('show-verification-bar', 'true');
          $element[0].classList.add('is-verification-changed');
        } else {
          $element[0].classList.remove('is-verification-changed');
        }
      }

      changeVerificationStatus();

      _system$
        .pipe(
          takeUntil(destroy$),
          filter((message) => message.action === 'statusChanged')
        )
        .subscribe(() => {
          changeVerificationStatus();
        });

      _verificationInfo$
        .pipe(
          takeUntil(destroy$),
          filter((message) => message.action === 'verificationStatusUpdated')
        )
        .subscribe(() => {
          changeVerificationStatus();
        });

      $scope.$onDestroy = () => {
        destroy$.next();
        destroy$.unsubscribe();
      };
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
