import '../../service/domain/http.js'
(function () {
  'use strict';
  controller.$inject = ['$q', 'http'];

  function controller($q, _http) {
    const activate = (options) => {
      const defer = $q.defer();
        const alias = 'promocodeActivate';
        const config = {
          params: options,
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };
        const data = {};

      _http.post(alias, data, config).then(
        (answer) => {
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        }
      );
      return defer.promise;
    };

    return {
      activate,
    };
  }

  app.factory('promocode', controller);
})();
