import { fromEvent } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return fromEvent(window, 'scroll');
  }

  app.factory('scroll$', controller);
})();
