(function () {
  'use strict';

  const directive = { name: 'regilyAvatarToReward' };
  rewardMapperController.$inject = ['$scope', 'system'];

  function rewardMapperController($scope, systemAPI) {
    /** @type {Promise<Avatar[]>}*/
    const avatars = systemAPI.avatars().then(
      ({ result }) => result,
      () => []
    );

    $scope.$watch('avatar', function (avatar) {
      if (!(avatar && avatar.id)) {
        return;
      }

      avatars.then((collection) => {
        const order = collection.findIndex(({ id }) => id === avatar.id);
        $scope.rewardCreated({ selection: createReward(avatar, order, $scope.descriptionFromLevels) });
      });
    });

    /**
     * @param {Avatar} avatar
     * @param {number} order
     * @param {number[]} extractDescriptionFrom
     * @return {SelectedReward}
     * */
    function createReward(avatar, order, extractDescriptionFrom) {
      let defaultRewardDescription = '';
      if (angular.isArray(extractDescriptionFrom)) {
        defaultRewardDescription = extractDescriptionFrom
          .reduce((phrases, levelIndex) => {
            return avatar.levels[levelIndex] ? [...phrases, avatar.levels[levelIndex].description] : phrases;
          }, [])
          .join(' ');
      }

      return {
        overviewBackground: order + 1, // regily uses 1-based ordering
        overviewRewardConfig: {
          defaultRewardTitle: avatar.levels[3].description,
          defaultRewardDescription,
        },
      };
    }
  }

  app.directive(directive.name, function () {
    return {
      restrict: 'A',
      controller: rewardMapperController,
      scope: {
        avatar: '=',
        descriptionFromLevels: '<',
        rewardCreated: '&',
      },
    };
  });
})();
