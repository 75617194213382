(function () {
  const directive = { name: 'dvsDragAndDrop' };

  function controller() {
    function link(scope, element, attrs) {
      element.bind('dragover dragenter', processDragOverOrEnter);
      element.bind('dragend dragleave', endDragOver);
      element.bind('drop', dropHandler);

      function dropHandler(angularEvent) {
        const event = angularEvent.originalEvent || angularEvent;
        const files = event.dataTransfer.files;

        event.preventDefault();

        element.removeClass('dragging error');

        if (scope.$parent.files.length >= 5) {
          return false;
        }

        if (files.length > 5) {
          return false;
        }

        if (scope.$parent.files.length + files.length > 5) {
          return false;
        }

        scope.$parent.addFiles(files);
      }

      function processDragOverOrEnter(angularEvent) {
        const event = angularEvent.originalEvent || angularEvent;
        if (event) {
          event.preventDefault();
        }

        const files = event.dataTransfer.items;

        event.dataTransfer.effectAllowed = 'copy';
        element.addClass('dragging');

        if (scope.$parent.files.length + files.length > 5) {
          element.addClass('error');
        }

        return false;
      }

      function endDragOver() {
        element.removeClass('dragging error');
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
