import '../../service/domain/state.js';
(function () {
  'use strict';

  const directive = { name: 'ngTapBack' };

  controller.$inject = ['state', '$window'];

  function controller(_state, $window) {
    const link = (scope, element, attrs) => {
      let tapping = false;

      element.bind('touchstart', function (e) {
        tapping = true;
      });

      element.bind('touchstart', function (e) {
        tapping = false;
      });

      element.bind('touchstart', function (e) {
        if (tapping) {
          if ($window.history.length >= 3) {
            $window.history.back();
          } else {
            _state.goto('home');
          }
        }
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
