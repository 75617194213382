import '../../service/domain/state.js';
import '../../service/analytics/analytics.js'
import { log } from 'util';

export const megaGameEvents = 'megaGameEvents';

class MegaGameEvents {
  static $inject = ['state', 'analytics'];

  constructor(state, analytics) {
    this.state = state;
    this.analytics = analytics;
  }
  handleEvent({ gameAction, payload }) {
    switch (gameAction) {
      case 'deposit':
      case 'home':
      case 'terms':
        this.state.goto(gameAction);
        break;
      case 'gameHalls':
        this.state.goto('gameHall');
        break;
      case 'pushToDataLayer':
        this.analytics.send(payload);
        break;
      default:
        console.warn('Unknown gameAction', gameAction);
    }
  }
}

app.factory(megaGameEvents, MegaGameEvents)
