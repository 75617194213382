/**
 * The format is expected to be: DD.MM.YYYY
 * The sequence can be different
 * The separator can be anything
 * The year can be two or four characters long
 */

app.filter('formatDate', function () {
  return function (date, neededDateFormat, sourceDateFormat) {
    if (!date) return;

    neededDateFormat = neededDateFormat || 'DD.MM.YYYY';
    sourceDateFormat = sourceDateFormat || 'DD-MM-YYYY';

    const result = getStrFromDate(getDateFromStrByMask(date, sourceDateFormat), neededDateFormat);

    return result || date;

    function getDateFromStrByMask(str, mask) {
      const year = getSubstrByMask(str, mask, 'Y');
      const month = getSubstrByMask(str, mask, 'M');
      const day = getSubstrByMask(str, mask, 'D');

      const date = new Date(
        `${new Date(`01-01-${year}`).getFullYear()}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      );
      const isDateValid = !isNaN(date.getTime()) && Number(day) === date.getDate();

      return isDateValid ? date : null;
    }

    function getStrFromDate(date, format) {
      if (!date) return null;

      const year = String(date.getFullYear());
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const YLocations = getSubstrLocations(format, 'Y');
      const MLocations = getSubstrLocations(format, 'M');
      const DLocations = getSubstrLocations(format, 'D');

      const replaceSubstr = (str, iStart, iEnd, newSubstr) => str.replace(str.substring(iStart, iEnd + 1), newSubstr);

      let result = format;

      result = replaceSubstr(
        result,
        YLocations[0],
        YLocations[YLocations.length - 1],
        YLocations.length === 4 ? year : year.slice(-2)
      );
      result = replaceSubstr(result, MLocations[0], MLocations[MLocations.length - 1], month);
      result = replaceSubstr(result, DLocations[0], DLocations[DLocations.length - 1], day);

      return result;
    }

    function getSubstrByMask(str, fullMask, characterMask) {
      let result = '';

      getSubstrLocations(fullMask, characterMask).forEach((i) => (result += str[i]));

      return result;
    }

    function getSubstrLocations(str, substr) {
      const result = [];
      let i = -1;

      while ((i = str.indexOf(substr, i + 1)) >= 0) result.push(i);

      return result;
    }
  };
});
