(function () {
  app.factory('seon', controller);

  function controller() {
    const seon_session = {};
    const uuid = crypto?.randomUUID?.() || uuidv4();

    seon.config({ session_id: uuid });
    seon.getBase64Session((encodedPayload) => {
      seon_session.session_id = uuid;
      seon_session.session = encodedPayload;
    });

    function uuidv4() {
      return crypto.getRandomValues
        ? ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (val) =>
            (val ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (val / 4)))).toString(16)
          )
        : null;
    }

    return {
      request(config) {
        if (config.meta?.type === 'api' && config.method === 'POST' && uuid) {
          config.data.seon_session = seon_session;
        }

        return config;
      },

      response(response) {
        return response;
      },
    };
  }
})();
