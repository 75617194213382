import '../../service/domain/http.js';
import '../../service/domain/user.js';
import '../../service/localStorage.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q', 'user', 'localStorage'];

  function controller(_http, $q, _user, _localStorage) {
    let _result = [];

    const o = {
      list,
      item,
      subscription,
      get result() {
        if (_user.status) {
          return _result.filter((i) => i.avatar === _user.profile.profile.avatar || !i.avatar);
        }
        return _result;
      },
      get object() {
        const result = this.result;
        return Object.assign(result.toObject('name'), { total_count: result.length });
      },
    };

    function list(options) {
      const defer = $q.defer();
      const alias = 'promoList';
      const affiliateParams = _localStorage.getAffiliateData();
      const params = !_user.status ? { ...affiliateParams, ...options } : options;
      const config = {
        params: params,
        meta: {
          type: 'api',
          alias,
        },
      };

      _http.get(alias, config).then(
        (answer) => {
          _result = answer.data.result;
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        },
      );

      return defer.promise;
    }

    function item(options) {
      const defer = $q.defer();
      const alias = 'promoItem';
      const config = {
        params: options,
        meta: {
          type: 'api',
          alias,
        },
      };

      _http.get(alias, config).then(
        (answer) => {
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        },
      );

      return defer.promise;
    }

    function subscription(data) {
      const defer = $q.defer();
      const alias = 'optInSubscription';
      const config = {
        cache: true,
        meta: {
          cache: true,
          type: 'api',
          alias,
        },
      };

      _http.put(alias, data, config).then(
        (answer) => {
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        },
      );

      return defer.promise;
    }

    return o;
  }

  app.factory('promo', controller);
})();
