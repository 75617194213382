import '../../../service/domain/documentVerification.js'
import '../../../service/domain/popup.js'
import '../../../service/domain/documentVerification.js'
import '../../../service/domain/user.js'
import '../../../service/rx/post-message$.js';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

(function () {
  controller.$inject = ['$scope', 'documentVerification', 'popup', 'verificationInfo$', 'user', 'postMessage$'];

  function controller($scope, _documentVerification, _popup, _verificationInfo$, _user, _postMessage$) {
    $scope.preloader = false;
    $scope.documentList = [];

    const destroy$ = new Subject();

    this.$onInit = () => {
      getStartedParams();
    };

    const subscription1 = _verificationInfo$
      .pipe(filter((item) => item.event === 'onfidoResult'))
      .subscribe((info) => {
        callAboutResult(info.data);
      });

    const subscription2 = _verificationInfo$
      .pipe(filter((item) => item.event === 'moveStatus'))
      .subscribe((item) => {
        moveStatus(item.data);
      });

    const subscriptionPreloader = _documentVerification.preloader$
      .pipe(filter((answer) => answer.message === 'onfidoPreloader'))
      .subscribe((answer) => {
        $scope.preloader = answer.preloader;
      });

    const documentList$ = _documentVerification.documentList$
      .subscribe((answer) => {
        $scope.documentList = answer;
      });

    // to do: remove method 'verifyDocuments' after changing method to 'verify' on all projects
    $scope.verifyDocuments = (requestedDocumentId, verificationProvider, documentType, iframeUrl) => {
      _documentVerification.selectVerificationFlow({ requestedDocumentId, verificationProvider, documentType, iframeUrl});
    };

    $scope.verify = ( document ) => {
      _documentVerification.selectVerificationFlow(document);
    };

    _postMessage$
      .pipe(
        takeUntil(destroy$)
      )
      .subscribe((event) => {
        try {
          const parsedData = JSON.parse(event);
          _documentVerification.handleEventByStatus(parsedData);
        } catch (error) {
          console.warn('Not Kreditz event', error);
        }
      });

    const moveStatus = (documentId) => {
      $scope.documentList.forEach((item) => {
        if (item.requestedDocumentId === documentId) {
          return (item.status = 'PENDING');
        }
        return;
      });
    };

    const callAboutResult = (data) => {
      $scope.preloader = true;

      _documentVerification.setDocuments.upload.done(data).then(() => {
        moveStatus(data.requestedDocumentId);

        $scope.preloader = false;
      });
    };

    const getStartedParams = () => {
      $scope.preloader = true;

      if (!_user.profile.verificationStatus) {
        $scope.preloader = false;

        return;
      }

      _documentVerification.getDocuments
        .list()
        .then((answer) => {
          $scope.documentList = answer.result;
        })
        .then(() => {
          $scope.preloader = false;
        });
    };

    this.$onDestroy = () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
      subscriptionPreloader.unsubscribe();
      documentList$.unsubscribe();
      destroy$.unsubscribe();
    };
  }

  new Controller('documentVerificationPage', controller);
})();
