import '../domain/promo.js';
import '../../service/configs/config.js'
import '../../service/domain/state.js';

const factoryName = 'promoItemResolver';

class Resolver {
  static $inject = ['$state', 'promo', 'config', 'state'];

  constructor($state, promo, config, state) {
    this.promo = promo;
    this.state = state;
    this.$state = $state;
    this.config = config;
  }

  gotoNotFound() {
    this.state.goto('notFound', {});
  }

  resolve() {
    const { name } = this.$state.transition.params() || '';
    return this.promo
      .item({ name })
      .then(({ result }) => result)
      .catch(() => this.gotoNotFound());
  }
}

app.factory(factoryName, Resolver);
