(function () {
  'use strict';

  function getUrlStringService() {
    return function getUrlString(params, keys = [], isArray = false) {
      const url = Object.keys(params)
        .map((key) => {
          const val = params[key];

          if ('[object Object]' === Object.prototype.toString.call(val) || angular.isArray(val)) {
            if (angular.isArray(params)) {
              keys.push('');
            } else {
              keys.push(key);
            }
            return getUrlString(val, keys, angular.isArray(val));
          }
            let tKey = key;

            if (keys.length > 0) {
              const tKeys = isArray ? keys : [...keys, key];
              tKey = tKeys.reduce((str, val) => {
                return '' === str ? val : `${str}[${val}]`;
              }, '');
            }
            if (isArray) {
              return `${tKey}[]=${val}`;
            }
              return `${tKey}=${val}`;

        })
        .join('&');

      keys.pop();
      return url;
    };
  }

  app.factory('getUrlString', getUrlStringService);
})();
