// https://github.com/catdad/canvas-confetti
(function () {
  'use strict';

  const directive = { name: 'confetti' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const script = document.createElement('script');
      script.src = `https://cdn.jsdelivr.net/npm/canvas-confetti@1.5.1/dist/confetti.browser.min.js`;
      document.head.appendChild(script);
      script.onload = function () {
        const config = scope.$eval(attrs.settings);
        confetti(config);
      };
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
