(function () {
  'use strict';

  const directive = { name: 'scrollElementToCenterOnStateChange' };

  controller.$inject = ['$window', '$timeout'];

  function controller($window, $timeout) {
    const link = (scope, element, attrs) => {
      const container = element[0];
      const config = scope.$eval(attrs[directive.name]) || {};

      const init = () => {
        $timeout(() => {
          const child = container.querySelector(config.element) || container.querySelector(config.stub);
          if (!child) return;

          if (child.scrollIntoView) {
            const scrollIntoViewOptions = config.scrollIntoViewOptions || { inline: 'center', block: 'end' };
            child.scrollIntoView(scrollIntoViewOptions);
          }

          const position = child.offsetLeft - $window.innerWidth / 2 + child.offsetWidth / 2;
          container.scrollLeft = position;
        }, 200);
      };

      scope.$on('$stateChangeSuccess', () => {
        init();
      });

      init();
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
