(function () {
  'use strict';
  const directive = { name: 'lindaValidateBirthdayFormat' };

  app.directive(directive.name, controller);

  function controller() {
    function link(scope, element, attrs, ctrl) {
      const getIsoFormat = (value) => {
        // return value in YYYY-MM-DD format

        if (!value) return;

        const splittedDate = value.split('-');

        // check if value has DD-MM-YYYY format
        if (!isNaN(new Date(`${splittedDate[1]}-${splittedDate[0]}-${splittedDate[2]}`).getTime())) {
          return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
        } 
          // else use MM-DD-YYYY format
          return `${splittedDate[2]}-${splittedDate[0]}-${splittedDate[1]}`;
        
      };

      const validator = (value) => {
        // value format is YYYY-MM-DD

        if (isNaN(new Date(value).getTime())) return false;
        if (new Date(value).getUTCDate() !== Number(value.split('-')[2])) return false;

        const userAgeInMs = new Date().getTime() - new Date(value).getTime();
        const userAge = Math.floor(userAgeInMs / (1000 * 60 * 60 * 24 * 365.25));

        return userAge >= 18 && userAge <= 100;
      };

      ctrl.$validators.over18 = function (modelValue, viewValue) {
        const value = modelValue || viewValue;
        return validator(value);
      };

      ctrl.$parsers.push(function (modelValue, viewValue) {
        const value = modelValue || viewValue;
        return getIsoFormat(value);
      });

      ctrl.$formatters.push((value) => {
        if (value) {
          const splittedDate = value.split('-');
          if (splittedDate[0].length === 4) {
            return splittedDate.reverse().join('-');
          }
          return value;
        }
        return;
      });
    }

    return {
      restrict: 'A',
      link,
      require: 'ngModel',
    };
  }
})();
