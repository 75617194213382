import '../../service/configs/config.js';
(function () {
  'use strict';

  controller.$inject = ['config'];

  function controller(_config) {
    const api = {
      version: 'v1',

      avatarList: '/avatar/list',
      trustlyInit: '/ident/init',
      trustlyAuth: '/ident/auth',
      trustlyStatus: '/ident/status',
      trustlyData: '/ident/data',
      bankInit: '/ident/init',
      bankAuth: '/ident/auth',
      bankLookup: '/ident/lookup',

      fulfillmentInfo: '/ident/user_info',

      userLogin: '/account/login',
      userLogout: '/account/logout',
      userInfo: '/account/info',
      userPing: '/account/ping',
      userEdit: '/account/update',
      userRegister: '/account/register',
      userRestore: '/account/restore',
      userChangePass: '/account/change_password',
      userBonus: '/account/bonus',
      userAvBonus: '/account/available_bonus',
      userChangeLimit: '/account/limits',
      userRemoveLimit: '/account/remove_limit',
      sessionLength: '/account/session_limit',
      userExclusion: '/account/exclusion',
      userClosure: '/account/close',
      userWin: '/account/last_win',
      userMultiLimits: '/account/multi_limits',
      userTermsChanged: '/account/accept_terms_and_conditions',
      userRefreshSession: '/account/refresh_session',
      userUpdateRequiredFields: '/account/update_required_fields',
      userUpdateOptionalFields: '/account/update_optional_fields',
      userBalanceDetails: '/account/balance_details',
      userSymplifyHash: '/account/symplify_hash',
      userTwoFactor: '/account/two_factor_auth',
      userTwoFactorEnable: '/account/two_factor_auth/code',
      userTwoFactorDisable: '/account/two_factor_auth/disable',
      userDocumentList: '/account/document_list',
      userYearReport: '/account/year_report',
      userNewlyBonuses: '/account/newly_assigned_bonuses',

      userSetMask: '/account/set_mask',

      userDKCoolingOff: '/account/cool_off_limit',
      userDKRealitySettings: '/account/reality_check',

      userSVRealitySettings: '/account/reality_check',
      userSVTokenRestore: '/account/restore_sv_token',

      userQuestions: '/secret_question/list',

      achievements: '/achievement/list',
      achievementItem: '/achievement/item',
      achievementTake: '/achievement/take',
      achievementInProgress: '/achievement/in_progress',
      achievementLastEarned: '/achievement/last_earned',
      achievementListProductCollections: '/achievement/list_product_collections',

      collectionList: '/collection/list',
      collectionHall: '/collection/hall',
      categoryList: '/category/list',

      shopItems: '/shop/list',
      shopBuy: '/shop/buy',

      subscriptionList: '/subscription/list',
      subscriptionChange: '/subscription/unsubscribe',

      jackpotLatest: '/jackpot/latest',

      userRestorePassword: '/account/change_password_by_token',
      userRecoveryLogin: '/account/recovery_login',

      userBonusActivate: '/account/bonus_activate', // bonus_id
      userResetBonus: '/account/bonus_reset', // bonus_id || нічого

      userActivateMail: '/account/activate',
      userResendActivationCode: '/account/reactivate',
      userPasswordReset: '/account/reset_password',
      validateUserData: '/account/validate_user_data',

      infoblock: '/infoblock/',
      infoblockReward: '/infoblock/reward',
      infoblockWin: '/infoblock/win',

      bannerList: '/banner/list',

      maskCollectionList: '/mask_collection/list',
      maskCollectionItem: '/mask_collection/list',
      maskCollectionGrab: '/mask_collection/grab',
      maskCollectionBuy: '/mask_collection/buy',

      gameItem: '/game/item/',
      gameList: '/game/list',
      gameLaunch: '/game/launch', // http://new.yoyocasino.com/ru/api/v1/game/launch?code=Catsino&system=rival&mode=demo&technology=F
      gameWinners: '/game/winners',
      gameCheck: '/game/check',
      gameRecentlyPlayed: '/account/last_game',
      gameToggleFav: '/favorites/toggle',

      gameSearch: '/game/search',
      gameCloseSession: '/game/close_game_session',

      sportLaunch: '/sport/launch',
      sportFeed: '/game-events-feed/feed',
      sportCheck: '/sport/check',

      lottoLaunch: '/lotto/launch',

      promoList: '/promo/list',
      promoItem: '/promo/item',
      luckyWinnerDaily: '/lucky-winner/daily', // custom promo bonus
      promocodeActivate: '/promocode/activate',
      holidayList: '/holidays/list',
      holidayProgress: '/promo/progress',
      holidayPromoStart: '/promo/start_level',
      holidayPromoComplete: '/promo/complete_level',
      optInSubscription: '/opt-in/subscription',

      partners: '/partner/list',
      icons: '/icon/list',

      seoItem: '/seo/item',

      pageItem: '/page/item/',
      pageList: '/page/list',

      pSystemCollection: '/paysystem/available',
      pSystemIcons: '/paysystem/icons',
      pSystemList: '/paysystem/list',
      pHistory: '/payment/history',
      pDeposit: '/payment/deposit',
      pWithdraw: '/payment/withdraw',
      pWCancel: '/payment/withdrawcancel',
      pPromoCode: '/payment/promocode',
      checkPayment: '/payment/status',
      pExchange: '/payment/loyalty_exchange',
      pInterac: '/payment/interac_details',
      pLastDevcodeDep: '/payment/quick_deposit',
      historyPaysystems: '/history/paysystems',
      pgwInit: '/payment/init_widget',

      pAuth: '/payment/auth',

      menuList: '/menu/list',

      providerList: '/brand/list',

      countryList: '/geo/countries',

      tournamentList: '/tournament/list',
      tournamentItem: '/tournament/item/',
      fastTournamentList: '/tournament/fast_list',
      fastTournamentHistory: '/tournament/fast_history',
      fastTournamentSubscribe: '/tournament/fast_subscribe',
      fastTournamentGetLeaderboard: '/tournament/fast_leaderboard/',
      dataCarPromo: '/tournament/tournament_promo_state',
      leaderBoardCarPromo: '/tournament/tournament_promo_leaderboard',

      systemMirrors: '/system/mirrors',
      systemInfo: '/system/info',

      nationalityList: '/nationality/list',
      professionList: '/profession/list',

      historyBet: '/history/bet', // old
      historyBetGame: '/history/game', // old
      historyBalanceFlow: '/history/balance_flow',

      history: '/history/',
      historyLastDep: '/history/payment_last',

      articleItem: '/article/item',

      vipList: '/level/list',

      christmas: '/christmas/list',
      christmasCards: '/christmas/game_cards',
      christmasGameResult: '/christmas/result',

      easterDaysList: '/easter/list',
      easterGameStart: '/easter/start',
      easterEggFound: '/easter/finish',

      halloweenWheel: '/wof/gifts',
      halloweenCalendar: '/wof/days',
      halloweenSpin: '/wof/spin',

      worldCupCalendar: '/promo/cup',

      sportTeam: '/collection/team',
      sportSquad: '/collection/squad',
      sportCard: '/collection/card',

      gameLevel: '/levels/game_levels',

      dashboard: '/dashboard/list',

      // todo add real link
      abandonedRegistration: '/abandoned-registration',
      collectRegistrationAnalytics: '/account/analitics',

      // Shooting
      shootingGifts: '/shooting/gifts',
      shootingShoot: '/shooting/shot',

      termsItem: '/terms/item',

      // ===================== MOBILE APP =====================

      currentVersion: '/system/mobile_version',

      // ===================== SWEDEN =========================

      // ===================== PORTUGAL =========================
      ptGameSessionInfo: '/game/last_session_summary',
      ptUserExclusion: '/account/exclusion',
      ptUserCancelLimit: '/account/cancel_limits',

      additionalFields: '/account/additional_fields/schema',

      playgroundsList: '/playground/list',
      playgroundsBuy: '/playground/buy',
      playgroundsAssignCityReward: '/playground/assign_city_reward',

      wallOfWins: '/wall_of_wins/list',

      // ===================== Notifications center =====================>
      notificationsSettings: '/notification-center/notification_settings',
      notificationsList: '/notification-center/notification',

      // zendesk to back
      infoToZendesk: '/account/send_additional_info_to_zendesk',

      // mga limits
      mgaDepositLimit: '/account/mga_limits_deposit',
      mgaSessionLimit: '/account/mga_limits_session',
      mgaLossesLimit: '/account/mga_limits_losses',
      mgaSessionLimitContinue: '/account/mga_limits_session_continue',
      mgaSelfExclusion: '/account/mga_limits_self_exclude',
      mgaClosure: '/account/mga_limits_closure',
      mgaWagerLimit: '/account/mga_limits_wager',

      // ===================== Document verification service =====================>
      documentVerificationUploadOptions: '/verification-documents/upload-options',
      documentVerificationUploadFile: '/verification-documents/upload_file',
      documentVerificationUploadDone: '/verification-documents/upload-done',
      documentVerificationRequestedDocumentsList: '/verification-documents/requested-documents-list',
      documentVerificationUploadedDocuments: '/verification-documents/uploaded-documents',

      // survey
      surveyList: '/survey/list',

      // v3
      documentVerificationDocuments: '/verification/documents',
      extendedTournamentItem: '/tournament-extended/item/',
      extendedTournamentList: '/tournament-extended/list',

      // balance flow v3
      balanceFlow: '/balances/history',
      balanceFlowFilters: '/balances/history/filters',
      balanceFlowItem: '/balances/history/action',

      // balance flow v2
      balanceFlowV2: '/history/list',
      balanceFlowFiltersV2: '/history/filters',
      balanceFlowItemV2: '/history/item',

      paymentDetails: '/payment/transaction_details',

      balanceHistory: '/history/balance_history',
      balanceHistoryFilters: '/history/balance_history_filters',

      // ====== Romanian Limits ====== //

      accountLimitsList: '/account/limit/list',
      accountLimitDeposit: '/account/limit/deposit',
      accountLimitLosses: '/account/limit/losses',

      // ====== Otp verification ====== //

      otpSmsVerify: '/otp/sms_verify_send',
      otpVerifyCode: '/otp/verify_code',

      // ====== Entity info with brand feature flags, like: FunId ====== //
      entityInfo: '/entity/info',

      footer: '/footer/item',
      footerVerify: '/footer/verify',
      affiliateInfo: '/affiliate/info'
    };

    api.ask = function (alias) {
      return `${_config.apiUrl}/${_config.currentLang}/api/${_config.apiVersion}${api[alias]}`;
    };

    return api;
  }

  app.factory('api', controller);
})();
