import '../../../service/domain/history.js';
import '../../../service/domain/historyBalanceFlow.js';
(function () {
  'use strict';
  const directive = { name: 'lindaBalanceFlowTypeFilter' };

  factory.$inject = ['history', 'historyBalanceFlow'];

  function factory(_history, _historyBalanceFlow) {
    // function compile( tElement, tAttr ){

    function link($scope, $element, $attrs, $ctrl, $transclude) {
      let previousElement = $element[0];

      if (!$scope.ngModel) {
        $scope.ngModel = [];
      }

      _historyBalanceFlow.selectedTypes = $scope.ngModel;

      _history
        .getFilter()
        .then((a) => {
          const types = a.result.type;
          for (let i = 0; i <= types.length - 1; i++) {
            $transclude($scope.$new(true), ($cloneElement, scope) => {
              const cloneElement = $cloneElement[0];

              scope.index = i;
              scope.type = types[i];

              const hash = Date.now().toString(36);
              const checkbox = cloneElement.querySelector('input[type="checkbox"]');
              const label = cloneElement.querySelector('label');
              checkbox.setAttribute('value', `${types[i].name}`);
              checkbox.setAttribute('id', `${hash}${i}`);
              checkbox.checked = $scope.ngModel.includes(types[i].name);
              label.setAttribute('for', `${hash}${i}`);

              checkbox.addEventListener('change', (e) => {
                $scope.$apply(() => {
                  $scope.ngModel.toggle(e.target.value, e.target.checked);
                  _historyBalanceFlow.updateTypes(e.target.value, e.target.checked);
                });
              });

              $ctrl.$formatters.push((modelValue) => {
                if (modelValue.includes(checkbox.value) !== checkbox.checked) {
                  checkbox.checked = !checkbox.checked;
                  _historyBalanceFlow.updateTypes(checkbox.value, checkbox.checked);
                }
                return modelValue;
              });

              previousElement.after(cloneElement);
              previousElement = cloneElement;
            });
          }
        })
        .catch((e) => {})
        .finally(() => {});
    }

    // }

    return {
      require: 'ngModel',
      restrict: 'A',
      transclude: 'element',
      link,
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, factory);
})();
