(function () {
  'use strict';
  const directive = {
    name: 'scrollToCurrentDate',
  };
  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link(scope, element) {
      const scrollToCurrentDate = $timeout(() => {
        element[0].querySelector('.today').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        clearTimeout(scrollToCurrentDate);
      }, 2000);
    }

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
