import '../../service/domain/http.js';
/**
 *
 *
 *
 *
 *
 */

(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    const vip = {
      object: {},
      collection,
    };

    function collection() {
      const defer = $q.defer();
        const alias = 'vipList';
        const config = {
          params: {
            limit: 1000,
          },
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };

      _http.get(alias, config).then(
        function (answer) {
          vip.object = answer.data.result.toObject('level');
          vip.object.total_count = answer.data.result.length;
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        }
      );

      return defer.promise;
    }

    return vip;
  }

  app.factory('vip', controller);
})();
