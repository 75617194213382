(function() {
  const redirectMessageData = {
    message: 'pwaRedirect',
    configUrl: $_CDN.redirector || '',
  };

  function removePartytownSwIfExists() {
    const targetScope = `${window.location.origin}/libPartytown/`;
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        if (registration.scope === targetScope) {
          registration
            .unregister()
            .then()
            .catch((error) => error);
        }
      }
    });
  }

  if ('serviceWorker' in navigator) {
    removePartytownSwIfExists();
    navigator.serviceWorker
      .register(`/base_sw.js`)
      .then(() => navigator.serviceWorker.ready)
      .then((swRegistration) => {
        swRegistration.active.postMessage(redirectMessageData);
      });
  }
})();
