(function () {
  'use strict';

  const directiveName = 'swipeToCloseNotification';

  app.directive(directiveName, controller);

  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link(scope, element, attrs) {
      const tapPosition = { start: 0, end: 0 };
      let distance = 0;
      let transition = 'none';
      const limits = {
        max: 100,
        min: -20,
      };
      let checked = false;

      element.bind('touchstart', function (e) {
        checked = false;
        tapPosition.start = e.changedTouches[0].pageY;
        transition = element.css('transition');
        element.css('transition', 'none');
      });

      element.bind('touchmove', function (e) {
        if (!checked) {
          tapPosition.end = e.changedTouches[0].pageY;
          distance = tapPosition.start - tapPosition.end;
          distance = Math.min(distance, limits.max);
          distance = Math.max(distance, limits.min);
          element.css('transform', 'translateY(' + -1 * distance + 'px)');
          element.css('opacity', 1 - (distance > 0 ? distance / 200 : 0));

          if (tapPosition.end <= 0) {
            removeNotification();
          }
        }
        e.preventDefault();
      });

      element.bind('touchend', function (e) {
        if (!checked) {
          element.css('transition', transition);
          removeNotification();
        }
        e.preventDefault();
      });

      function removeNotification() {
        checked = true;
        if (distance > limits.max * 0.5) {
          element.css('opacity', 0);
          $timeout(() => {
            element.remove();
          }, 300);
          scope.$apply(attrs[directiveName], element);
        } else {
          element.css('transform', '');
          element.css('opacity', '1');
        }
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
