(function () {
  'use strict';

  const directive = { name: 'keepScrollPosition' };

  controller.$inject = ['$timeout', '$state'];

  function controller($timeout, $state) {
    const scrollPosCache = {};

    const link = (scope, element, attrs) => {
      scope.$on('$stateChangeStart', function () {
        if ($state.$current) {
          const el = element[0].querySelector(attrs[directive.name]);
          if (el) {
            scrollPosCache[$state.current.name] = [el.scrollLeft, el.scrollTop];
          }
        }
      });

      scope.$on('$stateChangeSuccess', function () {
        const prevScrollPos = scrollPosCache[$state.current.name] || [0, 0];
        $timeout(function () {
          const el = element[0].querySelector(attrs[directive.name]);
          if (el) {
            el.scrollTo(prevScrollPos[0], prevScrollPos[1]);
          }
        }, 0);
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
