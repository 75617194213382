import '../service/validator/iban.js';
import '../service/validator/postcode.js';
import '../service/validator/password.js';
import '../service/validator/cpf.js';
import '../service/validator/addressCA.js';
import '../service/validator/account-validator.js';
import '../service/validator/name.js';
import '../service/validator/surname.js';
import '../service/validator/city.js';
import '../service/validator/address.js';
import '../service/validator/zipcode.js';
import '../service/validator/email.js';

(function () {
  'use strict';

  const directive = { name: 'validateAs' };
  controller.$inject = ['iban', 'postcode', 'password', 'cpf', 'addressCA', 'accountValidator', 'name', 'surname', 'city', 'address', 'zipcode', 'email'];

  function controller(_iban, _postcode, _password, _cpf, _addressCA, _accountValidator, _name, _surname, _city, _address, _zipcode, _email) {
    const link = (scope, element, attrs, ngModel) => {
      const type = attrs[directive.name] || null;
      const validator = {};
      const asyncValidator = {};

      switch (type) {
        case 'iban':
          validator.type = 'iban';
          validator.method = _iban.check;
          break;

        case 'cpf':
          validator.type = 'cpf';
          validator.method = _cpf.check;
          break;

        case 'postcode':
          validator.customValidators = _postcode.customValidators;
          break;

        case 'password':
          validator.customValidators = _password.customValidators;
          break;

        case 'addressCA':
          validator.type = 'addressCA';
          validator.method = _addressCA.check;
          break;

        case 'phone':
          phoneFormatter();
          asyncValidator.type = 'phone';
          asyncValidator.method = _accountValidator.asyncCheckPhone;
          break;

        case 'login':
          asyncValidator.type = 'login';
          asyncValidator.method = _accountValidator.asyncCheckLogin;
          break;

        case 'name':
          validator.customValidators = _name.customValidators;
          break;

        case 'surname':
          validator.customValidators = _surname.customValidators;
          break;

        case 'city':
          validator.customValidators = _city.customValidators;
          break;

        case 'address':
          validator.customValidators = _address.customValidators;
          break;

        // name this type zipcode as there is a type postcode, for not to change anything on brands
        case 'zipcode':
          validator.customValidators = _zipcode.customValidators;
          break;

        case 'email':
          validator.customValidators = _email.customValidators;
          break;

        default:
          validator.type = null;
          break;
      }

      if (angular.isFunction(validator.customValidators)) validator.customValidators(scope, element, attrs, ngModel);

      if (validator.type) {
        ngModel.$validators[validator.type] = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? validator.method(value) : true;
        };
      }

      if (asyncValidator.type) {
        ngModel.$asyncValidators[asyncValidator.type] = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? asyncValidator.method(value) : true;
        };
      }

      function phoneFormatter() {
        element.on('input', () => {
          const newValue = `+${element.val().replace(/[^0-9]/g, '')}`;
          ngModel.$setViewValue(newValue);
          ngModel.$render();

          ngModel.$validate();
        });
      }
    };

    return { restrict: 'A', require: '?ngModel', link };
  }

  app.directive(directive.name, controller);
})();
