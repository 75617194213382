import '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    return {
      collection(options) {
        const defer = $q.defer();
          const alias = 'jackpotLatest';
          const config = {
            params: options,
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('jackpot', controller);
})();
