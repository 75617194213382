import '../../service/domain/http.js';
import '../../service/domain/user.js';
import '../../service/rx/trustly$.js';
import '../../service/configs/zignsec.js';
(function () {
  'use strict';

  app.factory('zignsec.paynplay', controller);

  controller.$inject = ['http', '$q', 'user', 'trustly$', 'zignsec.config', '$cookies', '$window'];

  function controller(_http, $q, _user, _trustly$, _zignsec_config, $cookies, $window) {
    const adformcookieid = $window.Adform?.['_uid'];
    function requestParams(params) {
      const ls = angular.fromJson($window.localStorage.getItem('params') || '{}');

      ls.paymethod = _zignsec_config.paymethod || 'zignsec';
      ls.currency = _zignsec_config.currency;
      ls.affiliate = {};
      ls.locale = _zignsec_config.locale;
      ls.amount = api.data.amount;
      ls.personId = api.data.personId;

      if (api.data.order_id) ls.order_id = api.data.order_id;

      return Object.assign({}, ls, params);
    }

    function reset() {
      api.data.flow = null;
      api.data.amount = _zignsec_config.default;
      api.data.personId = '';
      api.data.step = 0;
      api.data.order_id = null;
    }

    let _step = 0;
      let _flow = null; // 'fast_deposit' - default || 'resume_play' - optional

    const api = {
      data: {
        order_id: null,
        amount: _zignsec_config.default,
        personId: '',
        reset,
      },

      init(params) {
        const defer = $q.defer();
          const alias = 'trustlyInit';
          const config = { meta: { alias } };
        const data = requestParams(params);
        if (adformcookieid) {
          data.adformcookieid = adformcookieid;
        }

        const fbpValue = $cookies.get('_fbp');
        if (fbpValue && data.affiliate) {
          data.affiliate.fbp = fbpValue;
        }

        Object.assign(api.data, data);

        _http.post(alias, data, config).then(
          (answer) => {
            this.data.order_id = answer.data.result.order_id || null;

            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      auth(params) {
        const defer = $q.defer();
          const alias = 'trustlyAuth';
          const config = {
            meta: { alias },
          };
        const data = requestParams(params);
        if (adformcookieid) {
          data.adformcookieid = adformcookieid;
        }

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      checkStatus(options) {
        const defer = $q.defer();
          const alias = 'trustlyStatus';
          const config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data.result);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      updateProfile(data) {
        const defer = $q.defer();
          const alias = 'trustlyData';
          const config = { meta: { alias } };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data.result);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };

    Object.defineProperty(api.data, 'step', {
      get() {
        return _step;
      },
      set(value) {
        _step = value;
        _trustly$.next({ action: 'step', data: value });
      },
    });

    Object.defineProperty(api.data, 'flow', {
      get() {
        return _flow || 'fast_deposit';
      },
      set(value) {
        _flow = value;
      },
    });

    return o;
  }
})();
