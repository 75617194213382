import '../domain/http.js';
import '../rx/ws$.js';
import '../configs/ws-events.js';
import '../missed-data.js';
import { filter } from 'rxjs/operators';

export const survey = 'survey';

class SurveyService {
  static $inject = ['http', 'ws$', 'wsEvents', 'missedData'];
  constructor(http, _ws$, _wsEvents, missedData) {
    this.http = http;
    this.missedData = missedData;

    _ws$.pipe(filter(({ event }) => event === _wsEvents.surveyUpdated)).subscribe(() => {
      this.missedData.init();
    });
  }

  getList() {
    return this.http.getData('surveyList', {})
      .then((answer) => {
        return answer.result;
      }).catch(e => e);
  };

}
app.service(survey, SurveyService);
