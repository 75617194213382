(function () {
  controller.$inject = ['$scope', '$interval'];

  function controller($scope, $interval) {
    let time = 0;
    let cb;
    let timer;
    $scope.time = time;

    const setTimer = () => {
      $interval.cancel(timer);
      timer = $interval(() => {
        if (time <= 0) {
          $interval.cancel(timer);
          if (angular.isFunction(cb)) {
            cb();
          }
          return;
        }
        time--;
        $scope.time = time;
      }, 1000);
    };

    this.$onChanges = () => {
      cb = this.callback;
      time = Math.round(this.start / 1000);
      if (time && time > 0) {
        setTimer();
      }
    };

    this.$onDestroy = () => {
      if (timer) {
        $interval.cancel(timer);
      }
    };
  }

  new Controller('countdown', controller, { start: '<', callback: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanCountdown${i}`, 'countdown');
  });
})();
