import '../../service/configs/config.js';
import '../../service/rx/ws$.js';
import '../../service/domain/account.js';
import '../../service/configs/ws-events.js';
import '../../service/domain/popup.js';
import '../../service/domain/user.js';
import '../../service/domain/state.js';
import '../../service/rx/system$.js';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'crabPopup' };
  const dict = $_DICT || { claw_games: [] };
  const claw_games = dict.claw_games;

  controller.$inject = ['config', 'ws$', 'account', 'wsEvents', 'popup', 'user', 'state', 'system$', '$rootScope'];

  function controller(_config, _ws$, _account, _wsEvents, _popup, _user, _state, _system$, $rootScope) {
    const link = (scope, element) => {
      const subscriptions = [];

      // eslint-disable-next-line angular/on-watch
      $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, error) {
        if (!toState.name.includes('claw_games')) {
          if (toParams.mode && toParams.name && claw_games.includes(toParams.name)) {
            event.preventDefault();
            // toParams.mode = null;
            _state.goto('claw', { name: toParams.name });
          }
        }
      });

      if (typeof CrabLoader === 'undefined') {
        const script = document.createElement('script');
        script.src = `/joxi/js/crab/sdk/launch.v2.js?t=${Date.now()}`;
        document.body.appendChild(script);
        script.onload = () => {
          run();
        };
      } else {
        run();
      }

      let crab = {
        destroy: () => {},
      };

      function run() {
        const appName = `popup${_config.device.mobile ? '-m' : ''}-${_config.siteName}`;

        // eslint-disable-next-line angular/window-service,new-cap
        window.CrabLoader({ name: appName, version: '1.0.0' }).then((Crab) => {
          crab = new Crab(null, {
            locale: _config.currentLang,
            brandProfile: _user.profile,
            brandFeature: _config.feature,
            destroyOnLocationChange: false,
            beforePopupOpen() {
              _popup.close();
            },
            toHome() {
              _state.goto('home');
            },
            toBonusCrab() {
              _state.goto('claw', {
                name: 'cash-crab',
              });
            },
            toCashCrab() {
              _state.goto('claw', {
                name: 'cash_crab',
              });
            },
            toDeposit() {
              _state.goto('deposit');
            },
            toTerms() {
              _state.goto('terms');
            },
            async updateProfile() {
              await _account.info({ nocache: true });
              return _user.profile;
            },
          });

          subscribe();
        });
      }

      function subscribe() {
        subscriptions[0] = _system$
          .pipe(filter((m) => m.action === 'login'))
          .subscribe((m) => {
            crab.updateParams({ brandProfile: _user.profile });
          });

        const achieve$ = _ws$
          .pipe(
            filter(({ event }) => event === _wsEvents.achieve),
            filter(({ data }) => data.achieve?.category === 'cash_crab'));

        const newBonusCrabAttempts$ = _ws$.pipe(filter(({ event }) => event === _wsEvents.newBonusCrabAttempts));

        subscriptions[1] = merge(achieve$, newBonusCrabAttempts$).subscribe(() => {
          crab.callEvent('newAttempt');
          _account
            .info({ nocache: true } )
            .then(() => {
              crab.updateParams({ brandProfile: _user.profile });
            })
            .catch(() => {})
            .finally(() => {});
        });
      }

      scope.$on('$destroy', () => {
        crab.destroy();
        subscriptions.forEach((s) => s.unsubscribe());
      });
    };

    return { link };
  }

  app.directive(directive.name, controller);
})();
