(function () {
  'use strict';

  const component = { name: 'lindaErrorModal' };

  controller.$inject = ['$scope', '$templateCache'];

  function controller($scope, $templateCache) {
    $scope.getTemplate = (code) => {
      const template = code ? `/error-${code}.html` : ``;
      return !$templateCache.get(template) ? `` : template;
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
