(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs, formCtrl) => {
      formCtrl.$successful = false;
      formCtrl.$setSuccessful = function () {
        this.$successful = true;
        if (angular.isFunction(this.$onSuccessful)) this.$onSuccessful();
      };
    };

    return {
      require: 'form',
      restrict: 'E',
      link,
    };
  }

  app.directive('form', controller);
})();
