import '../../service/rx/splash$.js';
(function () {
  'use strict';

  controller.$inject = ['splash$', '$q', '$interval'];

  function controller(_splash$, $q, $interval) {
    let _count = 0;
    let _done = false;
    let i = 0;

    const watcher = $interval(() => {
      if (_count === 0 && _done === false) {
        i++;
        if (i >= 6) {
          _done = true;
          _splash$.next('loaded');
          $interval.cancel(watcher);
        }
      }
    }, 50);

    return {
      request(config) {
        _count++;
        return config;
      },

      response(response) {
        _count--;
        return response;
      },

      responseError(response) {
        _count--;
        return $q.reject(response);
      },
    };
  }

  app.factory('splash', controller);
})();
