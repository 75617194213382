import '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    return {
      setData(data) {
        const defer = $q.defer();
          const alias = 'abandonedRegistration';
          const config = {
            meta: { alias },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('abandonedRegistration', controller);
})();
