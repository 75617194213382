import '../../service/verification-alert.js';
import '../../service/rx/system$.js';
import '../../service/domain/documentVerification.js';
import '../../service/domain/user.js';
import '../../service/localStorage.js';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

const componentName = 'dvsAlert';

class DvsAlert {
  static $inject = ['$scope', 'verificationAlert', '$document', '$element', 'system$', 'verificationInfo$', 'user', 'localStorage'];
  constructor($scope, verificationAlert, $document, $element, _system$, _verificationInfo$, _user, _localStorage) {
    const destroy$ = new Subject();
    const PARAMS = {
      localStorageKey: 'show-verification-bar',
      bodyHasNotificationClass: 'has-dvs-verification',
    };

    const bodyClassList = $document[0].body.classList;

    this.$onChanges = () => {
      changeStatus();
    };

    function changeStatus() {
      bodyClassList.remove(PARAMS.bodyHasNotificationClass);
      $scope.activeAlert = verificationAlert.getActiveAlert(PARAMS);
      $scope.isVerificationBlocked = !!_user.profile.groups?.length;

      if ($scope.activeAlert === 'REQUIRES_VERIFICATION' || $scope.activeAlert === 'VERIFIED') {
        bodyClassList.add(PARAMS.bodyHasNotificationClass);
      }
    }

    $scope.close = () => {
      bodyClassList.remove(PARAMS.bodyHasNotificationClass);
      _localStorage.remove(PARAMS.localStorageKey);

      $element.remove();
    };

    changeStatus();

    _system$
      .pipe(
        takeUntil(destroy$),
        filter(({ action }) => action === 'statusChanged'),
      )
      .subscribe(changeStatus);

    _verificationInfo$
      .pipe(
        takeUntil(destroy$),
        filter(({ action }) => action === 'verificationStatusUpdated'),
      )
      .subscribe(changeStatus);

    $scope.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }
}

app.component(componentName, {
  controller: DvsAlert,
  bindings: {
    localStorageKey: '<',
    bodyHasNotificationClass: '<',
  },
  template: app.getTU(componentName),
});
