(function (t) {
  'use strict';
  t.module('scrollSpyModule', []).directive('scrollSpy', [
    '$window',
    '$rootScope',
    '',
    function (e, n, r) {
      return {
        restrict: 'A',
        link: function (r, i, a) {
          var o = a.id + '-scrollSpy';
          (n[o] = new (function () {
            var t,
              r,
              i,
              o = this,
              s = !1,
              u = !1,
              l = !1,
              c = !1,
              f = document.documentElement,
              h = a.id || 'unknown element',
              p = document.getElementById(h),
              d = !1;
            (this.determinePosition = function () {
              if (s) {
                var e = window.pageYOffset || f.scrollTop;
                c || ((e + t < i || e > i + r) && ((c = !0), (l = !1), n.$broadcast('elementScrolledOutOfView', h))),
                  ((e + t >= i + 1 * r && i > e) || (e >= i && d)) &&
                    (u || ((u = !0), n.$broadcast('elementFirstScrolledIntoView', h)),
                    l || ((l = !0), (c = !1), n.$broadcast('elementScrolledIntoView', h)));
              }
            }),
              (this.takeMeasurements = function () {
                (t = e.innerHeight),
                  (r = p.offsetHeight),
                  (i = p.offsetTop),
                  t < r && (d = !0),
                  (s = !0),
                  o.determinePosition();
              });
            var $ = setInterval(function () {
              p.offsetHeight > 2 && (clearTimeout($), o.takeMeasurements());
            }, 50);
          })()),
            n.globalOnScrollFunctions || (n.globalOnScrollFunctions = []),
            n.globalOnScrollFunctions.push(n[o]),
            n.globalOnScroll ||
              ((n.globalOnScroll = function () {
                t.forEach(n.globalOnScrollFunctions, function (t, e) {
                  t.determinePosition();
                });
              }),
              (e.onscroll = n.globalOnScroll));
        },
      };
    },
  ]);
})(angular);
