(function () {
  'use strict';

  // eslint-disable-next-line no-extend-native
  Array.prototype.last = function () {
    return this[this.length - 1];
  };

  const formats = ['jpg', 'png', 'jpeg'];

  app.filter('imageProxy', function () {
    return function (url, params) {
      if (!url) return null;

      const urlArray = url.split('.');

      if (formats.find((format) => format === urlArray.last())) {
        return url + '?' + new URLSearchParams(params);
      }

      return url;
    };
  });
})();
