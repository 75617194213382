import '../../service/configs/config.js';
(function () {
  'use strict';

  controller.$inject = ['config', '$window', '$interval'];

  function controller(_config, $window, $interval) {
    const events = _config.p161 || null;
    const collectedEvents = [];

    const interval = $interval(() => {
      if ($window.p161 && angular.isFunction($window.p161.track) && angular.isFunction($window.p161.trackQueue)) {
        collectedEvents.forEach((event) => {
          $window.p161.track(event);
          $window.p161.trackQueue();
        });
        collectedEvents.length = 0;
      }
    }, 2000);

    if (!events) {
      $interval.cancel(interval);
    }

    return {
      track(eventName) {
        if (events && events[eventName]) collectedEvents.push(events[eventName]);
      },
    };
  }

  app.factory('p161', controller);
})();
