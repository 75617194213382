import '../../../service/domain/http.js';

export const extendedTournamentApi = 'extendedTournamentApi';

class Api {
  static $inject = ['$q', 'http'];

  constructor($q, http) {
    this.$q = $q;
    this.http = http;
  }

  getList(params = {}) {
    return new Promise((resolve) => resolve(this.http.getData('extendedTournamentList', params)));
  }

  getItem(options, params) {
    return new Promise((resolve) =>
      resolve(this.http.getData({ name: 'extendedTournamentItem', param: options.alias }, params)),
    );
  }
}

app.factory("extendedTournamentApi", Api);
