import '../../service/configs/config.js';
(function () {
  'use strict';

  const directive = { name: 'statusbar' };

  controller.$inject = ['config', '$window'];

  function controller(_config, $window) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]);

      const device = getDevice();

      setTheme(config[device]);

      function getDevice() {
        if (_config.mobileConfig.platform === 'ios') {
          return $window.innerWidth === 375 && $window.innerHeight === 768 ? 'iPhoneX' : 'ios';
        }
        if (_config.mobileConfig.platform === 'android') {
          return 'android';
        }
      }

      function setTheme(options) {
        if (options.style === 'light') {
          StatusBar.styleDefault(); // black font
          StatusBar.backgroundColorByHexString(options.bg || '#ffffff');
        }
        if (options.style === 'dark') {
          StatusBar.styleLightContent(); // white font
          StatusBar.backgroundColorByHexString(options.bg || '#000000');
        }
      }
    }

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
