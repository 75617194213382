import '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['$q', 'http'];

  function controller($q, _http) {
    const o = {
      object: {},
      list,
    };

    function list(options) {
      const defer = $q.defer();
        const alias = 'categoryList';
        const config = {
          params: options,
          cache: true,
          meta: {
            type: 'api',
            alias,
          },
        };

      _http.get(alias, config).then(
        (answer) => {
          o.object = answer.data.result.toObject('name');
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        }
      );

      return defer.promise;
    }

    return o;
  }

  app.factory('category', controller);
})();
