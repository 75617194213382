import '../../service/domain/http.js'
(function () {
  controller.$inject = ['$q', 'http'];

  app.factory('pgwApi', controller);

  function controller($q, _http) {
    return {
      getData(options) {
        const defer = $q.defer();
          const alias = 'pgwInit';
          const config = {
            params: options,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http
          .post(alias, { paymethod: 'pgw_widget' }, config)
          .then(({ data }) => {
            defer.resolve(data);
          })
          .catch(({ data }) => {
            defer.reject(data);
          });

        return defer.promise;
      },
    };
  }
})();
