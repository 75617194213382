(function () {
  'use strict';

  controller.$inject = ['$window'];

  function controller($window) {
    function link($scope, $element, $attrs, $ctrl, $transclude) {
      const localStorageName = 'achievments-tooltip';

      const agree = () => {
        $window.localStorage.setItem(localStorageName, 'true');
        $element[0].parentNode.remove();
      };

      if (!$window.localStorage.getItem(localStorageName)) {
        $transclude($scope.$new(true), (clone, scope) => {
          scope.agree = agree;
          $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);
        });
      }
    }

    return {
      restrict: 'E',
      transclude: 'element',
      link,
    };
  }

  app.directive('lindaAchievementTooltip', controller);
})();
