import '../../service/domain/state.js';
import '../../service/domain/payment.js';
import '../../service/rx/system$.js';
import '../../service/configs/config.js';
import angular from 'angular';
(function () {
  'use strict';

  const component = { name: 'lindaPaymentCallback' };

  controller.$inject = ['$scope', 'state', '$state', '$interval', 'payment', '$location', 'system$', 'config', '$window'];

  function controller($scope, _state, $state, $interval, _payment, $location, _system$, _config, $window) {
    let timerSeconds = 20;
    $scope.timer = `00:${timerSeconds}`;
    $scope.action = 'preloader';
    const interId = $state.params.identifier;
    const action = $state.params.act || $state.params.status;
    const transactionAct = $state.params.act;
    const webView = _config.browser.webView;
    $scope.payment = null;

    $scope.action = action;
    makeTimer();
    if (angular.isDefined(interId)) {
      _payment
        .getDetails({ identifier: interId })
        .then((answer) => {
          $scope.payment = answer.result;
        })
        .catch(() => {});
    }
    if (isOpenedInFrame()) {
      $window.parent.postMessage(`transaction-${transactionAct}`, '*');
      $scope.close = function () {
        $window.top.postMessage('close', '*');
      };
      $scope.openChat = function () {
        $window.top.postMessage('open-chat', '*');
      };
      return;
    }

    if (!isWebView() || isOpenedInWindow()) {
      $window.parent.postMessage(`transaction-${transactionAct}`, '*');
      $window.opener?.parent.postMessage(
        {
          action: 'transaction',
          status: action,
          reason: $location.search().reason,
        },
        '*',
      );
      $scope.close = function () {
        if ($window.opener && !$window.opener.closed && !webView) {
          $window.opener.parent.postMessage('close', '*');
        }
        _state.goto('home');
      };

      $scope.openChat = function () {
        if ($window.opener && !$window.opener.closed && !webView) {
          $window.opener.parent.postMessage('open-chat', '*');
        }
        _system$.next({ action: 'open chat' });
      };
      return;
    }

    $scope.close = function () {
      _state.goto('home');
    };

    $scope.openChat = function () {
      _system$.next({ action: 'open chat' });
    };

    function makeTimer() {
      const timer = $interval(() => {
        if (timerSeconds >= 0) {
          const formattedTimeWithSeconds = timerSeconds.toString().padStart(2, '0');
          $scope.timer = `00:${formattedTimeWithSeconds}`;
          timerSeconds--;
        } else {
          $interval.cancel(timer);
          $scope.close();
        }
      }, 1000);
    }
    function isWebView() {
      return webView;
    }

    function isOpenedInWindow() {
      return $window.opener && !$window.opener.closed;
    }
    function isOpenedInFrame() {
      return $window.self !== $window.top;
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
