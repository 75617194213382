import '../../service/domain/http.js';
import '../../service/domain/user.js';
(function () {
  'use strict';

  app.factory('lp', controller);

  controller.$inject = ['$q', 'http', 'user'];

  function controller($q, _http, _user) {
    return {
      exchange(options) {
        const defer = $q.defer();
          const alias = 'pExchange';
          const data = options;
          const config = {
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }
})();
