import '../../../service/domain/documentVerification.js';
import '../../../service/domain/documentVerification.js';
import '../../../service/analytics/kreditz-analytics.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'documentVerification', 'verificationInfo$', 'kreditzAnalytics'];

  function controller($scope, _documentVerification, _verificationInfo$, kreditzAnalytics) {
    $scope.files = [];
    $scope.preloader = false;
    $scope.disabledForm = false;
    $scope.success = false;
    $scope.errors = {};

    const fileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    const maxFileSize = 5242880; // 5Mb
    const maxTotalFilesSize = 10485760; // 10Mb
    const maxFilesNumber = 5;

    $scope.addFiles = (files) => {
      if ($scope.success) {
        return;
      }

      if (files?.length) {
        $scope.files.push(...files);
        validateFiles();
      }
    };

    const validateFiles = () => {
      clearErrors();

      if (!isFilesNumberValid()) {
        Object.assign($scope.errors, { filesNumberOverflow: true });
      }

      if (!isTotalFilesSizeValid()) {
        Object.assign($scope.errors, { filesSizeOverflow: true });
      }

      $scope.files.forEach((file, index) => validateFile(file, index));
      $scope.disabledForm = !areFilesValid();
    };

    const validateFile = (file, index) => {
      if (!isFileTypeValid(file)) {
        Object.assign(file.errors, { extension: true });
      }

      if (!isFileSizeValid(file)) {
        Object.assign(file.errors, { size: true });
      }

      if (!isFileUnique(file)) {
        Object.assign(file.errors, { duplicate: true });
      }

      if (index + 1 > maxFilesNumber) {
        Object.assign(file.errors, { filelistsize: true });
      }
    };

    const clearErrors = () => {
      $scope.errors = {};
      $scope.files.forEach((file) => (file.errors = {}));
    };

    const areFilesValid = () => {
      return Object.keys($scope.errors).length === 0 && $scope.files.every((file) => Object.keys(file.errors).length === 0);
    };

    const isFilesNumberValid = () => {
      return maxFilesNumber >= $scope.files.length;
    };

    const isTotalFilesSizeValid = () => {
      return maxTotalFilesSize >= $scope.files.reduce((totalFilesSize, file) => totalFilesSize + file.size, 0);
    };

    const isFileSizeValid = (file) => {
      return maxFileSize >= file.size;
    };

    const isFileUnique = (currentFile) => {
      return $scope.files.filter((file) => currentFile.size === file.size && currentFile.name === file.name).length <= 1;
    };

    const isFileTypeValid = (file) => {
      return fileTypes.includes(file.type);
    };

    const subscribe = _verificationInfo$
      .pipe(filter((item) => item.event === 'removeItem'))
      .subscribe((item) => {
        removeFile(item.data);
        validateFiles();
      });

    const removeFile = (index) => {
      $scope.files.splice(index, 1);
    };

    $scope.sendData = (id) => {
      $scope.preloader = true;

      _documentVerification.setDocuments.upload
        .file($scope.files, id)
        .then((answer) => {
          if (answer === 'true') {
            $scope.success = true;
            _verificationInfo$.next({
              event: 'moveStatus',
              data: id,
            });
          }
        })
        .catch((error) => {
          Object.assign($scope.errors, { unknownError: true });
        })
        .finally(function () {
          $scope.preloader = false;
        });
    };

    $scope.$on('updateFile', function (event, data) {
      $scope.addFiles(data);
      $scope.$apply();
    });

    $scope.onPopupClosed = (hasProviderKreditz) => {
      if (hasProviderKreditz) {
        kreditzAnalytics.onCloseButtonClicked();
      }
    };

    this.$onChanges = () => {
      $scope.addFiles(this.files);
    };

    this.$onDestroy = () => {
      subscribe.unsubscribe();
    };
  }

  new Controller('documentVerificationPopup', controller, {
    files: '<',
  });
})();


