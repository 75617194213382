(function () {
  'use strict';

  const directive = { name: 'scrollLeftAfterRepeat' };

  controller.$inject = [];

  function controller($timeout) {
    function link(scope, element, attrs) {
      const c = scope.$eval(attrs[directive.name]);

      if (scope.$last) {
        const parent = element.parent()[0];

        $timeout(() => {
          parent.scrollLeft = parent.querySelector(c.selector).offsetLeft - c.offset;
        }, 0);
      }
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
