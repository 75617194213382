import Swiper, { Navigation } from 'swiper';
import '../service/configs/config.js';
(function () {
  const directive = { name: 'swiperSlider' };

  controller.$inject = ['config', '$compile'];

  function controller(_config, $compile) {
    function link(scope, element, attrs) {
      if (!angular.isFunction(Swiper)) return;

      const o = scope.$eval(attrs[directive.name]);
      const config = _config.slider[o.config] || {};
      const instanceKey = o.instance || 'swiper';
      const collectionKey = o.collectionKey || 'collection';
      const slideClone = element[0].querySelector('.swiper-slide').cloneNode(true);
      const slidesWrapper = element[0].querySelector('.swiper-wrapper');

      scope.$watchCollection(collectionKey, (collection) => {
        if (collection && collection.length) {
          renewSlider(collection);
        }
      });

      function renewSlider(collection) {
        if (scope[instanceKey]) destroySlider();

        buildSlider(collection);
      }

      function buildSlider(collection) {
        repeatSlides(collection);

        scope[instanceKey] = initSwiper(collection);
      }

      function initSwiper(collection) {
        const instance = new Swiper(element[0], {
          ...config,
          modules: [Navigation],
          on: {
            init: bindCollectionToSlides(collection),
          },
        });

        return instance;
      }

      function bindCollectionToSlides(collection) {
        return function (swiper) {
          swiper.slides.forEach((slideNode, index) => {
            const indexFromCollection = slideNode.dataset.swiperSlideIndex ?? index;
            const slideScope = scope.$new();
            slideScope.slide = collection[indexFromCollection];
            $compile(slideNode)(slideScope);
          });
        };
      }

      function repeatSlides(collection) {
        const repeatedSlides = document.createDocumentFragment();

        collection.forEach(() => {
          repeatedSlides.appendChild(slideClone.cloneNode(true));
        });

        slidesWrapper.replaceChildren(repeatedSlides);
      }

      function destroySlider() {
        scope[instanceKey].destroy();
        scope[instanceKey] = null;
      }

      scope.$on('$destroy', destroySlider);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
