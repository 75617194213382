import '../../../service/rx/system$.js'
import '../../../service/domain/user.js'
import '../../../service/rx/splash$.js'
import '../../../service/configs/config.js'
import { Subject, combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  const name = 'lindaChatra';

  controller.$inject = ['system$', 'user', '$location', 'splash$', 'config'];

  function controller(_system$, _user, $location, _splash$, _config) {
    const chatActivation$ = new Subject();
    const openChatEvent$ = _system$.pipe(filter((message) => message.action === 'open chat'));

    combineLatest([chatActivation$, openChatEvent$]).subscribe(([openChatCb]) => openChatCb());

    const link = (scope) => {
      const destroy$ = new Subject();
      const w = window;
        const c = 'Chatra';

      w.ChatraID = 'xvrmYctCwKNdZq8Rk';
      w[c] =
        w[c] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (w[c].q = w[c].q || []).push(arguments);
        };

      const script = document.createElement('script');
      const target = document.scripts[document.scripts.length - 1];
      const src = 'https://call.chatra.io/chatra.js';

      script.async = true;
      script.src = src;
      target.parentElement.insertBefore(script, target.nextSibling);

      script.onload = onload;

      function activateChat() {
        // eslint-disable-next-line new-cap
        Chatra('openChat', true);
      }

      _splash$
        .pipe(
          filter((m) => m === 'loaded'),
          take(1),
          takeUntil(destroy$)
        )
        .subscribe((i) => {
          chatActivation$.next(activateChat);
        });

      scope.$onDestroy = () => {
        destroy$.next();
        destroy$.unsubscribe();
      };
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
