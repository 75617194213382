(function () {
  'use strict';

  const directive = { name: 'linda3dSlider' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs) => {
      $(element).children().addClass('slide-item');
      const showcase = $(element);
      const config = scope.$eval(attrs[directive.name]);

      const o = {
        onRendered(carousel) {
          // carousel.nearestItem().element.style.opacity = '1'
        },
        onAnimationFinished(e) {},
        onLoaded() {
          showcase.css('visibility', 'visible');
          showcase.css('display', 'none');
          showcase.fadeIn(1500);
          setTimeout(() => {
            showcase.data('carousel').go(5);
          }, 100);
        },
        buttonLeft: $(config.buttonLeft || '.next'),
        buttonRight: $(config.buttonRight || '.prev'),
      };
      const params = Object.assign({}, config, o);
      const c = showcase.Cloud9Carousel(params);
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
