import '../service/configs/config.js';

export const translations = 'translations';

class TranslationsService {
  static $inject = ['$translate', 'config'];

  constructor($translate, config) {
    this.$translate = $translate;
    this.config = config;
  }

  getTranslationsByPrefix(prefix) {
    return this.getAllTranslations(this.config.currentLang).then((translations) => {
      return Object.fromEntries(Object.entries(translations).filter(([key, value]) => key.startsWith(prefix)));
    });
  }

  getAllTranslations(lang) {
    return this.$translate.use(lang).then(() => {
      return this.$translate.getTranslationTable();
    }).catch(error => {
      console.error('Error while getting translations:', error);
    });
  }
}

app.factory(translations, TranslationsService);
