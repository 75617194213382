(function () {
  'use strict';

  app.factory('bb', controller);

  controller.$inject = ['$window'];

  function controller($window) {
    const qaLsParams = $window.localStorage.getItem('qa-params') || '';
    const qaConfig = {};
    let flag = false;
    qaLsParams
      .replace(/\s/g, '')
      .split(',')
      .forEach((item) => {
        const param = item.split('=');
        qaConfig[param[0]] = param[1];
        flag = true;
      });

    return {
      request(config) {
        if (config.meta?.type === 'api' && config.method === 'POST' && flag) {
          config.data.qa = qaConfig;
        }

        return config;
      },

      response(response) {
        return response;
      },
    };
  }
})();
