import { extendedTournamentApi } from '../../api/v3/extended-tournament-api.js';

export const extendedTournament = 'extendedTournament';

class Tournament {
  static $inject = ['extendedTournamentApi'];

  constructor(extendedTournamentApi) {
    this.extendedTournamentApi = extendedTournamentApi;
  }

  getSortedList(options) {
    return this.extendedTournamentApi.getList(options).then(({ result }) => {
      this.sortList(result);
    });
  }

  sortList(tournamentList) {
    this.casino = {};
    this.sport = {};

    tournamentList.forEach((item) => {
      const category = item.ledger_type.toLowerCase();
      const tournamentStatus = item.status.toLowerCase();

      if (!this[category]?.[tournamentStatus]) {
        this[category][tournamentStatus] = [item];
        return;
      }
      this[category][tournamentStatus].push(item);
    });
  }

  getItem(options, params) {
    return this.extendedTournamentApi.getItem(options, params);
  }
}

app.factory('extendedTournament', Tournament);
