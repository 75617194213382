(function () {
  const passwordVerify = 'passwordVerify';

  app.directive(passwordVerify, () => ({
    require: 'ngModel',
    link(scope, element, attrs, retypeControl) {
      const passwordNgModel = attrs[passwordVerify];
      // eslint-disable-next-line angular/no-private-call
      const passwordControl = retypeControl.$$parentForm.$$controls.find(
        // eslint-disable-next-line angular/no-private-call
        ({ $$attr: { ngModel } }) => ngModel === passwordNgModel
      );

      if (!passwordControl) return;

      const setPasswordVerifyValidity = () => {
        const passwordValue = passwordControl.$modelValue;
        const retypeValue = retypeControl.$modelValue;

        if (passwordValue && retypeValue && passwordValue !== retypeValue) {
          return retypeControl.$setValidity(passwordVerify, false);
        }

        retypeControl.$setValidity(passwordVerify, true);
      };

      scope.$watch(passwordNgModel, setPasswordVerifyValidity);
      retypeControl.$viewChangeListeners.push(setPasswordVerifyValidity);
    },
  }));
})();
