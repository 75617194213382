import '../../service/domain/http.js';
import '../../service/domain/user.js';
import '../../service/domain/popup.js';
import '../../service/analytics/kreditz-analytics.js';
import '../../service/rx/notification$.js';
import '../../service/rx/user$.js';
import { BehaviorSubject, Subject } from 'rxjs';

(function () {
  controller.$inject = [];

  function controller() {
    return new Subject();
  }

  app.factory('verificationInfo$', controller);
})();

(function () {
  controller.$inject = ['http', '$q', 'user', 'user$','popup', 'verificationInfo$', 'notification$', '$translate', 'kreditzAnalytics'];

  function controller(_http, $q, _user, _user$, _popup, _verificationInfo$, _notification$, $translate, kreditzAnalytics) {
    let verificationStatus = _user.profile.verificationStatus;
    const preloader$ = new BehaviorSubject(false);
    const documentList$ = new Subject();

    const kreditzEventType = {
      completed: 'User flow completed',
      authError: 'server error',
    };

    _user$.subscribe((user) => {
      if (user.profile.verificationStatus !== verificationStatus) {
        verificationStatus = user.profile.verificationStatus;
        _verificationInfo$.next({ action: 'verificationStatusUpdated' });
      }
    });

    return {
      preloader$,
      documentList$,
      getDocuments: {
        list() {
          return this.getData('documentVerificationRequestedDocumentsList');
        },

        collection() {
          return this.getData('documentVerificationDocuments');
        },

        getData(alias) {
          const defer = $q.defer();
          const config = {
            meta: { alias },
          };

          _http.get(alias, config).then(
            (answer) => {
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      setDocuments: {
        upload: {
          options(requestedDocumentId) {
            const data = {
              requestedDocumentId,
            };
            return this.setData('documentVerificationUploadOptions', data);
          },

          file(files, documentsId) {
            const data = files;
            return this.setData('documentVerificationUploadFile', data, documentsId);
          },

          done(data) {
            return this.setData('documentVerificationUploadDone', data);
          },

          setData(alias, data, documentsId) {
            const defer = $q.defer();

            if (documentsId) {
              alias = {
                name: alias,
                param: documentsId,
              };
              const formData = new FormData();
              for (let i = 0; i < data.length; i++) {
                if (Object.keys(data[i].errors).length === 0) {
                  formData.append(data[i].name, data[i]);
                }
              }
              data = formData;
            }

            const config = {
              meta: { alias },
            };

            if (documentsId) {
              config.headers = { 'Content-Type': undefined };
            }

            _http
              .post(alias, data, config)
              .then(
                (answer) => {
                  defer.resolve(answer.data);
                },
                (answer) => {
                  defer.reject(answer.data);
                }
              )
              .catch((error) => {
                defer.reject(error);
              });
            return defer.promise;
          },
        },
      },

      updateDocumentList() {
        this.getDocuments
          .list()
          .then((answer) => {
            documentList$.next(answer.result);
          });
      },

      selectVerificationFlow(providerParams) {
        switch(providerParams.verificationProvider) {
          case 'ONFIDO':
            this.uploadDocument(providerParams);
            break;
          case 'BACK_OFFICE':
            this.openProviderPopup(providerParams);
            break;
          case 'KREDITZ':
            kreditzAnalytics.onOpenButtonClicked();
            this.openProviderPopup(providerParams);
            break;
        }
      },

      uploadDocument(providerParams) {
        preloader$.next( { message: 'onfidoPreloader', preloader: true }  );
        this.setDocuments.upload.options(providerParams.requestedDocumentId).then(({ result }) => {
          this.openProviderPopup({result, verificationProvider: providerParams.verificationProvider});
          preloader$.next( {message: 'onfidoPreloader', preloader: false} );
        });
      },

      openProviderPopup(providerParams) {
        this.documentData = providerParams;
        const popupConfig = {
          name: 'document-verification',
          content: providerParams,
        };

        _popup.open(popupConfig);

        if(providerParams.verificationProvider === 'KREDITZ') {
          kreditzAnalytics.onFrameOpened();
        }
      },

      handleEventByStatus(event) {
        switch (event.status) {
          case 'success':
            this.handleSuccessEvent(event);
            break;
          case 'error':
            this.handleErrorEvent(event);
            break;
          default:
            break;
        }
      },

      handleSuccessEvent(event) {
        if(event.message === kreditzEventType.completed) {
          this.onUserFlowCompleted();
        }
      },

      handleErrorEvent(event) {
        if(event.message === kreditzEventType.authError) {
          _notification$.next({
            type: 'error',
            code: 0,
            text: $translate.instant('kreditz.notification.auth.error.message'),
          });
        }
      },

      onUserFlowCompleted() {
        this.setDocuments.upload.done(this.documentData).then(() => {
          kreditzAnalytics.onUploadDocumentsFinished();
          this.updateDocumentList();
          _notification$.next({
            type: 'success',
            code: 0,
            text: $translate.instant('kreditz.notification.success.message'),
          });
        });
        _popup.close();
      },
    };
  }

  app.factory('documentVerification', controller);
})();
