(function () {
  'use strict';

  const directive = { name: 'scrollElementToCenter' };

  controller.$inject = ['$window', '$timeout'];

  function controller($window, $timeout) {
    const link = (scope, element, attrs) => {
      const padding = 10;
      const container = element[0];

      $timeout(() => {
        const child = container.querySelector(attrs[directive.name]);

        if (!child) return;

        if (child.scrollIntoView) {
          child.scrollIntoView({ inline: 'center', block: 'end' });
        }

        const position = child.offsetLeft - $window.innerWidth / 2 + child.offsetWidth / 2;
        container.scrollLeft = position;
      }, 200);
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
