import '../service/domain/state.js'
import '../service/configs/config.js'
(function () {
  'use strict';

  const directive = { name: 'goBack' };

  controller.$inject = ['state', 'config', '$state', '$rootScope'];

  function controller(_state, _config, $state, $rootScope) {
    const link = (scope, element, attrs) => {
      // - TODO: Need to refactoring full directive. Has trouble with history api, when run game from playtech, maybe more providers.
      let curentPage = $state.current;
      let isBackEvent = false;

      const backEvent = $rootScope.$on('$stateChangeSuccess', () => {
        if (isBackEvent) {
          return;
        }
        isBackEvent = false;
        curentPage = $state.current;
      });

      if (_config.device.mobile) {
        element.bind('tap', go);
      } else {
        element.bind('click', go);
      }

      function go() {
        if (_state.history.length > 1) {
          isBackEvent = true;
          _state.goback();
        } else {
          _state.goto('home');
        }
      }

      $scope.$on('$destroy', () => {
        backEvent();
      })
    };
    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
