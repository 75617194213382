import { fromEvent, merge } from 'rxjs';
import { bufferCount, filter } from 'rxjs/operators';
(function () {
  'use strict';

  const event = new Event('tap', { bubbles: true });

  const touches = merge(
    ...['touchstart', 'touchmove', 'touchend'].map((n) => fromEvent(document, n))
  ).pipe(bufferCount(2, 1));

  touches
    .pipe(filter((i) => i[0].type === 'touchstart' && i[1].type === 'touchend'))
    .subscribe((x) => x[1].target.dispatchEvent(event));
})();
