(function () {
  controller.$inject = ['$window'];

  function controller($window) {
    return {
      get login() {
        return $window.localStorage.getItem('rememberedLogin') || null;
      },
      set login(value) {
        localStorage.setItem('rememberedLogin', value);
      },

      get name() {
        return $window.localStorage.getItem('rememberedName') || null;
      },
      set name(value) {
        localStorage.setItem('rememberedName', value);
      },

      get surname() {
        return $window.localStorage.getItem('rememberedSurname') || null;
      },
      set surname(value) {
        localStorage.setItem('rememberedSurname', value);
      },

      get avatar() {
        return $window.localStorage.getItem('rememberedAvatar') || null;
      },
      set avatar(value) {
        localStorage.setItem('rememberedAvatar', value);
      },

      get accountId() {
        return $window.localStorage.getItem('accountId') || null;
      },
      set accountId(value) {
        localStorage.setItem('accountId', value);
      },
    };
  }

  app.factory('remembered', controller);
})();
