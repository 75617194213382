import './otp-verification-api.js';
import { Subject } from 'rxjs';

const otpVerification = 'otpVerification';

class Verification {
  static $inject = ['otpVerificationApi'];

  constructor(otpVerificationApi) {
    this.otpVerificationApi = otpVerificationApi;
  }

  otpVerified$ = new Subject();
  phone_verification_hash = '';

  sendSms(phone) {
    return this.otpVerificationApi
      .sendSms({ phone })
      .then((answer) => {
        this.phone_verification_hash = answer.result.phone_verification_hash;
        return answer;
      })
      .catch((error) => {
        throw new Error();
      });
  }

  isOtpFlowNeeded(phoneHash) {
    return typeof phoneHash !== 'undefined';
  }

  sendCode(code) {
    const optVerificationData = {
      otp_code: code,
      phone_verification_hash: this.phone_verification_hash,
    };
    return this.otpVerificationApi.sendCode(optVerificationData);
  }
}

app.factory(otpVerification, Verification);
