(function () {
  'use strict';

  const directive = { name: 'validateCpr' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs, ctrl) => {
      const format = 'DDMMYY';

      const formatWithDash = (value) => {
        const stringVal = `${value}`;
        const formatted = `${stringVal.substr(0, format.length)}-${stringVal.substr(format.length, stringVal.length)}`;
        return formatted;
      };

      const validator = (value) => {
        // expected that value has format DDMMYY...
        const formattedDateStr = value.substr(0, format.length);
        const day = formattedDateStr.substr(0, 2);
        const month = formattedDateStr.substr(2, 2);
        const year = `20${formattedDateStr.substr(4, 2)}`;
        const date = new Date(`${year}-${month}-${day}`);

        return !isNaN(date.getTime()) && Number(day) === date.getDate();
      };

      ctrl.$validators.validateCpr = function (modelValue, viewValue) {
        const value = modelValue || viewValue;
        return value ? validator(value) : false;
      };

      ctrl.$parsers.push(function (modelValue, viewValue) {
        const value = modelValue || viewValue;
        return value ? formatWithDash(value) : value;
      });
    };

    return {
      restrict: 'A',
      require: 'ngModel',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
