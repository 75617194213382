import {holidayApiService} from '../service/periodicals/holidayApi.js';

(function () {
  'use strict';

  const directive = { name: 'getPromoLinksToScope' };

  controller.$inject = ['holidayApiService'];

  function controller(_holidayApiService) {
    const holidayCollection = [];

    function link($scope) {
      $scope.holidayCollection = holidayCollection;

      _holidayApiService.list().then((data) => {
        $scope.holidayCollection = data.result;
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }
  app.directive(directive.name, controller);
})();
