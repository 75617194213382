(function () {
  'use strict';

  const directive = { name: 'removeIfPwa' };

  controller.$inject = ['$window'];

  function controller($window) {
    function link($scope, $element, $attrs, $ctrl, $transclude) {
      const pwaPopupClosed = $window.localStorage.getItem('pwaPopupClosed');
      const pwaApp = localStorage.getItem('pwa');

      $scope.close = () => {
        $element[0].nextElementSibling.remove();
        $window.localStorage.setItem('pwaPopupClosed', 'true');
      };

      if (!pwaPopupClosed && !pwaApp) {
        $transclude($scope, (clone) => {
          $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);
        });
      }
    }

    return {
      restrict: 'A',
      link,
      transclude: true,
    };
  }

  app.directive(directive.name, controller);
})();
