export class CloudflareCaptcha {
  constructor() {
    if (!$_CDN.cfRecaptcha?.key) {
      throw new Error("CloudflareCaptcha key is required.");
    }
    if (!$_CDN.cfRecaptcha?.sdk) {
      throw new Error("CloudflareCaptcha sdk is required.");
    }
    this.key = $_CDN.cfRecaptcha?.key;
    this.url = $_CDN.cfRecaptcha?.sdk;
  }

  captchaExecuted = false;
  elementId = 'cf-captcha';

  addTurnstileElement() {
    const container = document.createElement('div');
    container.id = this.elementId;
    container.style.position = 'absolute';
    container.style.left = '-9999px';
    container.style.display = 'none';
    document.body.append(container);
  }

  addScriptInDom(url) {
    const scriptRecaptcha = document.createElement('script');
    scriptRecaptcha.src = url;
    document.head.appendChild(scriptRecaptcha);

    this.addTurnstileElement();
  }

  init() {
    this.addScriptInDom(this.url);
  }

  execute(action = 'noAction') {
    if (window.turnstile) {
      return new Promise((resolve) => {
        if(this.captchaExecuted) {
          turnstile.reset();
        }
        turnstile.execute(`#${this.elementId}`, {
          sitekey: `${this.key}`,
          action: action,
          appearance: 'interaction-only',
          'response-field': false,
          retry: 'never',
          'refresh-expired': 'never',
          callback: (token) => {
            this.captchaExecuted = true;
            resolve({ 'captcha-response': token });
          },
        });
      });
    }
  }
}
