import '../service/domain/user.js'
import '../service/rx/user$.js'
import '../service/domain/state.js'

export const missedData = 'missedData';

class MissedData {
  static $inject = ['user', 'user$', 'state'];

  constructor(_user, _user$, _state) {
    this.user = _user;
    this.state = _state;
    this.userStream = _user$;
  }

  checkOnMissedData() {
    return this.user.status && this.user.profile?.requiredFields?.length
      || this.user.profile?.profile?.is_profile_complete === false
  }

  init() {
    this.userStream.subscribe(() => {
      if (this.checkOnMissedData()) {
        this.state.goto('missedData');
      }
    });
  }
}

app.factory(missedData, MissedData);
