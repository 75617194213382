(function () {
  'use strict';

  const directive = { name: 'mDatepicker' };

  controller.$inject = ['$state', '$window'];

  function controller($state, $window) {
    const link = (scope, element, attrs) => {
      const config = scope.$eval(attrs[directive.name]);

      let defaultDate = scope.$eval(attrs.ngModel);
      let tapping = false;

      element.bind('touchstart', function (e) {
        tapping = true;
      });

      element.bind('touchmove', function (e) {
        tapping = false;
      });

      element.bind('touchend', function (e) {
        if (tapping && $window.datePicker) {
          $window.datePicker.show(
            {
              date: defaultDate ? new Date(defaultDate) : new Date(),
              mode: config.mode || 'date',
              minDate: config.minDate === 'today' ? new Date() : new Date(config.minDate) || '',
              maxDate: config.maxDate === 'today' ? new Date() : new Date(config.maxDate) || '',
              androidTheme:
                device.manufacturer === 'samsung'
                  ? $window.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT
                  : $window.datePicker.ANDROID_THEMES.THEME_HOLO_LIGHT,
            },
            (date) => {
              if (date) {
                const newDate = formatDate(date);

                element[0].value = newDate;
                defaultDate = newDate;

                if (angular.isFunction(config.onChange)) {
                  config.onChange();
                }
              }
            }
          );
        }
      });

      function formatDate(date) {
        const d = new Date(date);
          let month = '' + (d.getMonth() + 1);
          let day = '' + d.getDate();
          const year = d.getFullYear();

        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }

        return [year, month, day].join('-');
      }
    };
    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
