(function () {
  'use strict';

  const directive = { name: 'lindaFullscreen' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const selector = attrs[directive.name];

      element.on('click', function () {
        const isInFullScreen =
          (document.fullscreenElement && document.fullscreenElement !== null) ||
          (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
          (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
          (document.msFullscreenElement && document.msFullscreenElement !== null);

        const e = document.querySelector(selector);
          const docElm = document;

        if (!isInFullScreen) {
          if ('requestFullscreen' in e) {
            e.requestFullscreen();
          } else if ('webkitRequestFullscreen' in e) {
            e.webkitRequestFullscreen();
            // document.addEventListener( "webkitfullscreenchange", resize );
          } else if ('mozRequestFullScreen' in e) {
            e.mozRequestFullScreen();
          } else if ('msRequestFullscreen' in e) {
            e.msRequestFullscreen();
          }
        } else {
          if (docElm.exitFullscreen) {
            docElm.exitFullscreen();
          } else if (docElm.webkitExitFullscreen) {
            docElm.webkitExitFullscreen();
          } else if (docElm.mozCancelFullScreen) {
            docElm.mozCancelFullScreen();
          } else if (docElm.msExitFullscreen) {
            docElm.msExitFullscreen();
          }
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
