import Parallax from 'parallax-js';

(function(){

    'use strict';

    const directive = { name: 'parallax' };

    app.directive( directive.name, controller );

    controller.$inject = ['$interval'];

    function controller($interval){

        function link( scope, element, attrs ){

            let i = 0;
            const interval = 50;
            const max = 2000 / interval;

            const watcher = $interval( () => {
                const status = typeof Parallax !== 'undefined';
                i ++;

                const params = scope.$eval( attrs[directive.name] ) || {};

                if( status ){

                    const parallaxInstance = new Parallax( element[0], params );
                  $interval.cancel( watcher );

                }

                if( i >= max ){
                    clearInterval( watcher );
                }

            }, interval );

/*
            const parallaxInstance = new Parallax( element[0], {
                relativeInput: true
            } );
*/

        }

        return {
            restrict: 'A',
            link
        };
    }

})();
