import '../../../service/zignsec/zignsec.paynplay.js';
import '../../../service/domain/modal.js';
import '../../../service/rx/trustly$.js';
import '../../../service/domain/state.js';
import '../../../service/configs/config.js';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'lindaZignsecFrame' };

  factory.$inject = ['zignsec.paynplay', 'modal', 'trustly$', 'state', '$timeout', 'config', '$window'];
  function factory(_paynplay, _modal, _trustly$, _state, $timeout, _config, $window) {
    const link = async (scope, element, attrs) => {
      scope.preloader = true;
      element[0].src = '/preloader.html';

      let order_id;
        let auth_request = false;

      let interval$;

      let isCheckStatusRequestEnded = true;

      function success() {
        _state.goto('home');
      }

      function auth(options) {
        auth_request = true;
        _paynplay
          .auth(options)
          .then(() => {
            success();
          })
          .catch((e) => {
            if (e.messages[0]?.code === 5054) {
              $timeout(() => {
                _modal
                  .open('zignsec-registration', {
                    profile: e.messages[0].data.profile,
                    is_registration_bonuses_shown: e.messages[0].data.is_registration_bonuses_shown,
                    token: order_id,
                  })
                  .then(() => {
                    success();
                  })
                  .catch(() => {})
                  .finally(() => {});
              }, 0);
            }
          });
      }

      function checkStatus() {
        const params = {
          order_id: _paynplay.data.order_id,
          paymethod: _paynplay.data.paymethod || 'zignsec',
        };

        isCheckStatusRequestEnded = false;
        _paynplay
          .checkStatus(params)
          .then((statusData) => {
            if (statusData.status === 'verified') {
              statusData.token = _paynplay.data.order_id;
              auth(params);
              interval$.unsubscribe();
            }
          })
          .catch(() => {
            interval$.unsubscribe();
          })
          .finally(() => {
            isCheckStatusRequestEnded = true;
          });
      }

      function startIntervalForCheckingStatus() {
        interval$ = interval(1000)
          .pipe(filter(() => isCheckStatusRequestEnded))
          .subscribe(() => {
            checkStatus();
          });
      }

      $window.addEventListener('message', goToFinalStepAfterFrameSuccess);

      function goToFinalStepAfterFrameSuccess(m) {
        switch (m.data) {
          case 'preloader:success':
            if (!auth_request) {
              startIntervalForCheckingStatus();
              $window.removeEventListener('message', goToFinalStepAfterFrameSuccess);
            }
            break;

          case 'preloader:fail':
            _trustly$.next({ action: 'step', data: 0 });
            $window.removeEventListener('message', goToFinalStepAfterFrameSuccess);
            break;

          default:
            break;
        }
      }

      try {
        const frameData = await _paynplay.init(scope.zignsecParams);
        if (
          (_config?.jurisdiction === 'denmark' && _paynplay.data.paymethod === 'zignsec-mitid') ||
          _paynplay.data.paymethod === 'zignsec_finland'
        ) {
          localStorage.setItem('cpr', frameData.result.order_id);
          $window.open(frameData.result.url, '_self');
        }
        order_id = frameData.result.order_id;
        element[0].sandbox = 'allow-forms allow-scripts allow-same-origin allow-top-navigation';
        element[0].src = frameData.result.url;
      } finally {
        scope.preloader = false;
      }

      scope.$onDestroy = () => {
        interval$?.unsubscribe();
        $window.removeEventListener('message', goToFinalStepAfterFrameSuccess);
      };
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
