import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { gsap } from 'gsap';
import { summerSounds } from '../../sounds/summerSounds.js';
import '../../service/configs/config.js';

(function () {
  app.directive('summerHolidayScene', controller);
  controller.$inject = ['soundPlayer', 'config'];

  function controller(soundPlayer, _config) {
    function link(scope, element, attrs) {
      scope.summerLoader = false;

      const env = _config.production ? '' : _config.domain;

      const modelHighlightUrl =  env + '/joxi/summer-promo/arrow.glb';
      const coinModelUrl = env + '/joxi/summer-promo/coin.glb';
      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();
      const canvasId = 'summerCanvas';
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
      const controls = new OrbitControls(camera, renderer.domElement);
      const dirLight = new THREE.DirectionalLight(0xffffff, 1);
      const gltfLoader = new GLTFLoader();
      const gameTime = 60000;
      const timeClickCoin = 3000;
      const timeCoinRemove = 500;
      let arrOfMesh = [];
      controls.enablePan = false;
      gsap.to(camera.position, { y: 11, x: 20, z: 20, duration: 3 });
      gsap.fromTo(camera.rotation, { y: 0 }, { y: 0.73, duration: 3 });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(element[0].clientWidth, element[0].clientHeight);
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.domElement.id = canvasId;
      element[0].appendChild(renderer.domElement);
      renderer.setClearColor(0x000000, 0);
      camera.position.set(600, 300, 600);
      scene.add(new THREE.HemisphereLight(0xffffff, 0x000000, 0.4));
      dirLight.position.set(300, 150, 300);
      scene.add(dirLight);
      scope.timer = 0;

      controls.target.set(0, 1, 0);
      controls.update();
      controls.minDistance = 15;
      controls.maxDistance = 60;
      controls.minPolarAngle = 0.4;
      controls.maxPolarAngle = 1.6;
      controls.minAzimuthAngle = Math.PI / 100;
      controls.maxAzimuthAngle = Math.PI / 2;

      const arrowModels = [];
      const coinModels = [];
      let showArrowAnimation;
      const randomArrowIndex = (arrayLength) => {
        return Math.floor(Math.random() * arrayLength);
      };
      const arrowAnimation = (arrow) => {
        gsap.to(arrow.position, { z: 2, duration: 1, repeat: -1, yoyo: true });
      };
      const animateRandomArrow = (arrayOfArrows) => {
        const arrayRandomIndex = randomArrowIndex(arrayOfArrows.length);
        const randomArrowObject = arrayOfArrows[arrayRandomIndex];
        randomArrowObject.visible = true;
        arrowAnimation(randomArrowObject);
      };
      const buildClickableMesh = (copyScene) => {
        const geometry = new THREE.SphereGeometry(1.5, 1.5, 1.5);
        const material = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.callback = objectClickHandler;
        mesh.userData.parent = copyScene;
        mesh.position.set(...copyScene.position);
        scene.add(mesh);
        arrOfMesh.push(mesh);
      };

      if (scope?.todayBonus && scope?.todayBonus.dayStatus === 'today') {
        const coordinatesOfCoins = scope?.todayBonus?.coordinates;
        const cityModelUrl = env + `/joxi/summer-promo/towns/${scope?.todayBonus.name}.glb`;
        scope.summerLoader = true;

        gltfLoader.load(
          cityModelUrl,
          function (gltf) {
            scope.summerLoader = false;
            const model = gltf.scene;
            scene.add(model);
            animate();
            scope.timer = gameTime;

            soundPlayer.playTrack(summerSounds.gameStart);
            soundPlayer.playTrack(summerSounds.gameBackground);
            soundPlayer[summerSounds.gameBackground].loop = true;
            soundPlayer[summerSounds.gameBackground].volume = 0.4;

            model.traverse(function (node) {
              if (node.isMesh) {
                node.geometry.dispose();
                node.material.dispose();
              }
            });
          },
          undefined,
          function (e) {
            console.error(e);
          }
        );

        gltfLoader.load(
          coinModelUrl,
          function (gltf) {
            const modelCoin = gltf.scene;
            modelCoin.rotation.x = -Math.PI / 2;

            coordinatesOfCoins.forEach((item, i) => {
              const coinModel = modelCoin.clone();
              coinModels.push(coinModel);
              coinModel.position.set(item[0], item[1], item[2]);
              scene.add(coinModel);
              buildClickableMesh(coinModel);
            });

            modelCoin.traverse(function (node) {
              if (node.isMesh) {
                node.geometry.dispose();
                node.material.dispose();
              }
            });

            scope.isSummerTimerFinish = function () {
              gltfLoader.load(
                modelHighlightUrl,
                function (gltf) {
                  const modelHighlight = gltf.scene;
                  modelHighlight.name = 'highlight';
                  modelHighlight.scale.set(0.15, 0.15, 0.15);
                  modelHighlight.rotation.x = Math.PI / 2;
                  modelHighlight.position.set(0, 0, 1);
                  coinModels.forEach((coinModel) => {
                    const arrow = modelHighlight.clone();
                    arrow.visible = false;

                    arrowModels.push(arrow);
                    coinModel.add(arrow);
                  });
                  animateRandomArrow(arrowModels);

                  modelHighlight.traverse(function (node) {
                    if (node.isMesh) {
                      node.geometry.dispose();
                      node.material.dispose();
                    }
                  });
                },
                undefined,
                function (error) {
                  console.error(error);
                }
              );
            };
          },
          undefined,
          function (error) {
            console.error(error);
          }
        );
      }
      function objectClickHandler(obj) {
        soundPlayer.playTrack(summerSounds.collectCoin);
        if (soundPlayer[summerSounds.collectCoin]) {
          soundPlayer[summerSounds.collectCoin].playbackRate = 0.5;
        }

        soundPlayer.playTrack(summerSounds.coinAnimation);

        const parent = obj.userData.parent;
        const highlight = parent.getObjectByName('highlight');
        parent.remove(highlight);

        arrOfMesh = arrOfMesh.filter((e, i) => {
          if (e !== obj) {
            return e;
          }
          coinModels.splice(i, 1);
          const arrow = arrowModels.splice(i, 1);
          showArrowAnimation = arrow[0]?.visible ? null : true;
        });

        setTimeout(() => {
          scene.remove(parent);
        }, timeCoinRemove);

        if (arrOfMesh.length > 0) {
          return window.postMessage('coinTaken');
        }
        window.postMessage('gameFinished');
        soundPlayer.playTrack(summerSounds.allCollected);
      }

      function onWindowResize() {
        renderer.setPixelRatio(window.devicePixelRatio);
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      }

      function detectX(event) {
        const rect = element[0].getBoundingClientRect();
        return ((event.clientX - rect.left) / rect.width) * 2 - 1;
      }

      function detectY(event) {
        const rect = element[0].getBoundingClientRect();
        return -((event.clientY - rect.top) / rect.height) * 2 + 1;
      }

      let isClickBlock = false;

      function onDocumentMouseDown(event) {
        event.preventDefault();
        mouse.x = detectX(event);
        mouse.y = detectY(event);
        if (scope.step || isClickBlock) {
          return;
        }

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(arrOfMesh);
        if (intersects.length) {
          intersects[0].object.callback(intersects[0].object);
          if (arrowModels.length && !showArrowAnimation) {
            setTimeout(() => {
              animateRandomArrow(arrowModels);
            }, 2000);
          }
        }
        if (!intersects.length) {
          soundPlayer.playTrack(summerSounds.missClick);
        }
        isClickBlock = true;
        setTimeout(function () {
          isClickBlock = false;
        }, timeClickCoin);
      }
      function onDocumentMouseMove(event) {
        event.preventDefault();

        mouse.x = detectX(event);
        mouse.y = detectY(event);
        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObjects(arrOfMesh, true);
        const canvas = document.getElementById(canvasId);
        if (intersects.length) {
          canvas.style.cursor = 'pointer';
        } else {
          canvas.style.cursor = 'default';
        }
      }

      let animationFrameId;

      function animate() {
        animationFrameId = requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }

      const canvas = document.getElementById(canvasId);
      canvas.addEventListener('pointerdown', onDocumentMouseDown, false);
      canvas.addEventListener('mousemove', onDocumentMouseMove, false);
      window.addEventListener('resize', onWindowResize, false);

      scope.$on('$destroy', () => {
        cancelAnimationFrame(animationFrameId);
        while (scene.children.length > 0) {
          const child = scene.children[0];
          scene.remove(child);
          child.dispose?.();
        }
        renderer.renderLists.dispose();
        renderer.dispose();
        canvas.removeEventListener('pointerdown', onDocumentMouseDown, false);
        canvas.removeEventListener('mousemove', onDocumentMouseMove, false);
        window.removeEventListener('resize', onWindowResize, false);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
