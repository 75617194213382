import '../../service/domain/http.js'
import {holidayListJson} from './holidaList.js';
export const holidayApiService = 'holidayApiService';
const listCasino = holidayListJson;
// const listSport = holidayListJsonSport
// const bonusData = progreessData;

class holidayApi {
  static $inject = ['$q', 'http']

  constructor($q, http) {
    this.$q = $q;
    this.http = http;
  }

  list(params = {}) {
    const alias = 'holidayList'; const defer = this.$q.defer(); const config = {
      params, meta: {
        type: 'api', alias,
      },
    };
    this.http
      .get(alias, config)
      .then(({data}) => {
        defer.resolve(data);
      })
      .catch(() => {
        defer.reject();
      })
      .finally(() => {
      });
    return defer.promise;
  };

  progress(params = {}) {
    const alias = 'holidayProgress'; const defer = this.$q.defer(); const config = {
      params, meta: {
        type: 'api', alias,
      },
    };
    this.http
      .get(alias, config)
      .then(({data}) => {
        defer.resolve(data)
      })
      .catch(() => {
        defer.reject();
      })
      .finally(() => {
      });
    return defer.promise;
  };

  complete(data = {}) {
    const alias = 'holidayPromoComplete'; const defer = this.$q.defer(); const config = {
      meta: {alias},
    };
    this.http
      .post(alias, data, config)
      .then((data) => {
        defer.resolve(data);
      })
      .catch((error) => {
        defer.reject(error);
      })
      .finally(() => {
      });
    return defer.promise;
  }
  start(data = {}) {
    const alias = 'holidayPromoStart'; const defer = this.$q.defer(); const config = {
      meta: {alias},
    };
    this.http
      .post(alias, data, config)
      .then((data) => {
        defer.resolve(data);
      })
      .catch((error) => {
        defer.reject(error);
      })
      .finally(() => {
      });
    return defer.promise;
  }
}

app.factory(holidayApiService, holidayApi);
