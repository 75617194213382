import '../../service/domain/http.js';
import '../../service/mobile-app/version.js';
import '../../service/configs/config.js';
import '../../service/configs/trustly.js';
/**
 *
 *
 *          countries()
 *
 *
 */

(function () {
  'use strict';

  controller.$inject = ['http', '$q', '$state', 'version', 'config', 'trustly.config', '$http', 'localStorage', '$window'];

  function controller(_http, $q, $state, _version, _config, _trustly_config, $http, _localStorage, $window) {
    return {
      mode: {
        paynplay: _trustly_config.enabled,
      },

      systemInfo: {},

      info() {
        const defer = $q.defer();
          const alias = 'systemInfo';
          const config = {
            params: {},
            cache: true,
            meta: { type: 'api', alias },
          };

        const testDate = $window.localStorage.getItem('test-date') || '';
        if (testDate.length) {
          config.params.today = testDate;
        }

        _http
          .get(alias, config)
          .then(({ data }) => {
            this.systemInfo = data.result;
            defer.resolve(data);
          })
          .catch((error) => defer.reject(error));

        return defer.promise;
      },
      getSystemInfo() {
        if (Object.keys(this.systemInfo).length === 0) {
          return this.info().then((info) => {
            return info.result;
          });
        }
        return new Promise((resolve) => resolve(this.systemInfo));
      },
      questions() {
        const defer = $q.defer();
          const alias = 'userQuestions';
          const config = {
            params: {},
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      mobile: {
        currentVersion() {
          const defer = $q.defer();
            const alias = 'currentVersion';
            const config = {
              params: {},
              cache: true,
              meta: {
                type: 'api',
                alias,
              },
            };

          _http.get(alias, config).then(
            (answer) => {
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },

        updateCheck() {
          const defer = $q.defer();
            const alias = 'currentVersion'; // todo: need new method
            const config = {
              params: {
                // -                                limit: 1000
              },
              cache: true,
              meta: {
                type: 'api',
                alias,
              },
            };

          _http.get(alias, config).then(
            (answer) => {
              if (_version.update(answer.data.result[_config.mobileConfig.platform])) {
                $state.go('update.home', { params: answer.data.result[_config.mobileConfig.platform] });
              } else {
                defer.resolve(answer.data);
              }
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      countries() {
        const defer = $q.defer();
          const alias = 'countryList';
          const affiliateParams = _localStorage.getAffiliateData();
          const config = {
            params: {
              limit: 1000,
              ...affiliateParams,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      avatars() {
        const defer = $q.defer();
          const alias = 'avatarList';
          const config = {
            params: {
              limit: 1000,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      json(options) {
        const defer = $q.defer();

        $http
          .get(options.url)
          .then((answer) => {
            defer.resolve(answer.data);
          })
          .catch((error) => {
            defer.reject(error);
          });

        return defer.promise;
      },

      nationality() {
        const defer = $q.defer();
          const alias = 'nationalityList';
          const config = {
            params: {
              limit: 1000,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );
        return defer.promise;
      },

      professions() {
        const defer = $q.defer();
          const alias = 'professionList';
          const config = {
            params: {
              limit: 1000,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );
        return defer.promise;
      },

      document_list(options) {
        const defer = $q.defer();
          const alias = 'userDocumentList';
          const config = {
            params: {
              limit: 1000,
              ...options,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );
        return defer.promise;
      },
    };
  }

  app.factory('system', controller);
})();
