// https://mymth.github.io/vanillajs-datepicker/#/
(function () {
  const directive = { name: 'balanceFlowDatepicker' };

  function controller() {
    function link($scope, $element, $attrs, $ngModelCtrl) {
      const element = $element[0];
      const defaultFormat = 'yyyy-mm-dd';
      const { modelFormat = defaultFormat, viewFormat = defaultFormat } = $scope.$eval($attrs[directive.name]) || {};

      let changeFromModel = false;

      const updateModel = (modelValue) => {
        $scope.$apply(() => $ngModelCtrl.$setViewValue(modelValue));
      };

      const datepickerOptions = {
        autohide: true,
        format: viewFormat,
      };

      const datepickerInstance = new Datepicker(element, datepickerOptions);

      element.addEventListener('changeDate', () => {
        if (changeFromModel) {
          return (changeFromModel = false);
        }
        const formattedPickedDate = datepickerInstance.getDate(modelFormat);
        updateModel(formattedPickedDate);
      });

      $scope.$on('$destroy', () => {
        datepickerInstance.destroy();
      });

      $ngModelCtrl.$parsers.push((value) => {
        return value.toISOString();
      });

      $ngModelCtrl.$formatters.push((value) => {
        if (value) {
          const result = new Date(value);
          changeFromModel = true;
          datepickerInstance.setDate(result);
          return result;
        }
        return null;
      });
    }

    return {
      restrict: 'A',
      link,
      require: 'ngModel',
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, controller);
})();
