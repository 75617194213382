(function () {
  'use strict';
  // eslint-disable-next-line no-extend-native
  Date.prototype.sToDHMS = function (ms) {
    let d = Math.floor(ms / 86400);
    ms -= 86400 * d;
    if (d < 10) {
      d = '0' + d;
    }

    let h = Math.floor(ms / 3600);
    ms -= 3600 * h;
    if (h < 10) {
      h = '0' + h;
    }

    let m = Math.floor(ms / 60);
    if (m < 10) {
      m = '0' + m;
    }

    let s = ms - 60 * m;
    if (s < 10) {
      s = '0' + s;
    }

    return [d + '', h + '', m + '', s + ''];
  };
})();

(function () {
  'use strict';
  // eslint-disable-next-line no-extend-native
  Date.prototype.removeYears = function (y) {
    this.setFullYear(this.getFullYear() - (y || 0));
    return this;
  };
})();

(function () {
  'use strict';

  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  // eslint-disable-next-line no-extend-native
  Date.prototype.toISODate = function () {
    return this.getUTCFullYear() + '-' + pad(this.getUTCMonth() + 1) + '-' + pad(this.getUTCDate());
  };
})();
