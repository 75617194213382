(function () {
  'use strict';

  const directive = { name: 'horizontalScrollElement' };

  controller.$inject = ['$window', '$timeout'];

  function controller($window, $timeout) {
    const link = (scope, element, attrs) => {
      const container = element[0];

      $timeout(() => {
        const config = scope.$eval(attrs[directive.name]);
        const child = container.querySelector(config.class);
        const padding = config.padding || 10;
        let position = null;
        if (config.scrollTo === 'left') {
          position = child.offsetLeft - container.offsetLeft + container.scrollLeft - padding;
        } else {
          position = child.offsetLeft - $window.innerWidth / 2 + child.offsetWidth / 2;
        }
        container.scrollLeft = position;
      }, 200);
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
