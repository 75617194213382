import '../../service/domain/lp.js';
import '../../service/helper.js';
import '../../service/domain/user.js';
(function () {
  'use strict';

  const component = { name: 'lindaLpExchangeForm' };

  controller.$inject = ['$scope', 'lp', '$rootScope', 'helper', 'user'];

  function controller($scope, _lp, $rootScope, _helper, _user) {
    $scope.preloader = false;
    $scope.formData = {
      amount: 100,
    };
    $scope.realMoney = 0;

    $scope.$watch('formData.amount', (newVal, oldVal) => {
      const amount = newVal * _user.profile.level_info.exchange;
      $scope.realMoney = amount || 0;
    });

    $scope.inputValue = () => {
      return $scope.formData.amount ? $scope.formData.amount : 0;
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      _lp.exchange($scope.formData).then(
        (answer) => {
          $rootScope.$broadcast('renew_bonus_list');
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
