export class GoogleCaptcha {
  constructor() {
    if(!$_CDN.recaptcha?.sdk) {
      throw new Error("GoogleCaptcha sdk is required.");
    }
    if(!$_CDN.recaptcha?.key) {
      throw new Error("GoogleCaptcha key is required.");
    }
    this.sdk = $_CDN.recaptcha?.sdk;
    this.key = $_CDN.recaptcha?.key;
  }

  addStyles() {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = '.grecaptcha-badge{ visibility: hidden;}';
    document.body.append(styleElement);
  }

  addScriptInDom(url) {
    const scriptRecaptcha = document.createElement('script');
    scriptRecaptcha.src = url;
    document.body.appendChild(scriptRecaptcha);
    this.addStyles();
  }

  init() {
    this.addScriptInDom(`${this.sdk}?render=${this.key}`);
  }

  execute(action = 'noAction') {
    if (window.grecaptcha) {
      return new Promise((resolve, reject) => {
        return window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(this.key, { action: action })
            .then((token) => {
              resolve({
                'g-recaptcha-response': token,
              });
            })
            .catch(reject);
        });
      });
    }
  }
}
