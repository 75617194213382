import '../../service/configs/config.js';
import '../../service/rx/system$.js';
import '../../service/domain/promo.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'sliderPromo' };

  controller.$inject = ['config', 'system$', 'promo'];

  function controller(_config, _system$, _promo) {
    function link($scope, $element, $attrs, $ctrl, $transclude) {
      const o = $scope.$eval($attrs[directive.name]);

      const modelParam = o.modelParam || 'name';

      const slickBox = $element.parent();
      slickBox.slick(_config.slider[o.config]);

      let _collection = [];

      const s = {
        set currentSlide(v) {
          $ctrl.$setViewValue(_collection[v][modelParam]);
        },
        get currentSlide() {
          const index = _collection.findIndex((i) => i[modelParam] === $scope.ngModel);
          return index === -1 ? _config.slider[o.config].initialSlide || 0 : index;
        },
        set collection(v) {
          removeSlides(_collection.length);
          addSlides(v);
          _collection = v;
        },
      };

      s.collection = new Array(o.count || 1).fill({ skeleton: true });

      getCollection();

      const subscription = _system$
        .pipe(filter((m) => m.action === 'statusChanged'))
        .subscribe(() => {
          getCollection();
        });

      $scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });

      slickBox.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        s.currentSlide = nextSlide;
      });

      function getCollection() {
        const params = {};
        if (o.count) params.count = o.count;
        if (o.category) params.category = o.category;
        _promo
          .list(params)
          .then((a) => {
            s.collection = a.result;

            slickBox.slick('slickGoTo', s.currentSlide, true);

            $ctrl.$formatters.push((value) => {
              if (value) slickBox.slick('slickGoTo', s.currentSlide);
              return value;
            });
          })
          .catch((e) => {})
          .finally(() => {});
      }

      function addSlides(items) {
        items.forEach((i) => {
          $transclude($scope.$new(true), (clone, scope) => {
            scope.item = i;
            slickBox.slick('slickAdd', clone);
          });
        });
      }

      function removeSlides(count) {
        for (let i = 0; i < count; i++) {
          slickBox.slick('slickRemove', 0);
        }
      }
    }

    return {
      restrict: 'A',
      transclude: 'element',
      link,
      require: 'ngModel',
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, controller);
})();
