import { Subject } from 'rxjs';

(function () {
  'use strict';
  const g0 = 'gringos';
    const c0 = '$_CONFIG';
    const d0 = 'directive';

  const slideID = 0;
  function createHero(params) {
    const heroName = params.p;
    return [
      'resize$',
      function (_resize$) {
        return {
          restrict: 'C',
          link(scope, element, attrs) {
            const app = new PIXI.Application({
              transparent: true,
              resizeTo: element[0],
            });

            let subscription;
              let subscription1;
              let hero;
              let selected = false;

            element[0].appendChild(app.view);
            app.loader.add(heroName, `img/assets/${heroName}.json`).load(onAssetsLoaded);

            app.stage.interactive = true;

            function onAssetsLoaded(loader, res) {
              hero = new PIXI.spine.Spine(res[heroName].spineData);
              hero.x = app.screen.width / 2;
              hero.y = app.screen.height;

              setSize(window.innerWidth);

              hero.state.setAnimation(0, 'shadow', true);

              app.stage.addChild(hero);

              subscription = _resize$.subscribe((e) => {
                setSize(e.target.innerWidth);
              });
              select(slideID);
              subscription1 = select$.subscribe((id) => {
                select(id);
              });

              function select(id) {
                const h = window[c0][g0][id];
                if (h.n === params.n) {
                  selected = true;
                  hero.state.setAnimation(0, 'stand', true);
                } else {
                  if (selected === true) {
                    hero.state.setAnimation(0, 'shadow', true);
                  }
                  selected = false;
                }
              }
            }

            function setSize(width) {
              if (width >= 1920) {
                hero.scale.set(1);
              } else if (width >= 1680 && width < 1920) {
                hero.scale.set(0.85);
              } else if (width >= 1440 && width < 1680) {
                hero.scale.set(0.77);
              } else if (width >= 1024 && width < 1440) {
                hero.scale.set(0.59);
              }
            }

            scope.$on('$destroy', () => {
              subscription.unsubscribe();
              subscription1.unsubscribe();
            });
          },
        };
      },
    ];
  }

  const h0 = window[c0][g0] || [];
  if (h0.length) {
    window.select$ = new Subject();
  }
  h0.forEach((i) => {
    app[d0](i.dn, createHero(i));
  });
})();
