import { fromEvent } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return fromEvent(window, 'resize');
  }

  app.factory('resize$', controller);
})();
