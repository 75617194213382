(function () {
  'use strict';
  app.filter('getDomainName', controller);
  controller.$inject = ['$window'];

  function controller($window) {
    return () => {
      const domain = $window.location.hostname.replace('www.', '').split('.');
      return domain[0] === 'm' ? domain[1] : domain[0];
    };
  }
})();
