import '../../service/configs/config.js';
import '../../service/domain/promo.js';
import '../../service/domain/system.js';
import '../../service/domain/content-buffer.js';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = ['config', '$timeout', 'promo', 'system', 'contentBuffer', '$window'];

  function controller(_config, $timeout, _promo, _system, _contentBuffer, $window) {
    const $_REGISTRATION = $window.$_REGISTRATION || {};
    const sliders = {};
    const events$ = new Subject();
    const eventTypes = {
      initPromotionsSlider: 'init-promotions-slider',
      initAvatarsSlider: 'init-avatars-slider',
    };

    function bindSliders(firstSliderName, secondSliderName) {
      const firstSlider = sliders[firstSliderName];
      const secondSlider = sliders[secondSliderName];

      $timeout(() => {
        firstSlider.controller.control = secondSlider;
        secondSlider.controller.control = firstSlider;
      });
    }

    function registerSlider(name, slider) {
      sliders[name] = slider;
    }

    function unregisterSlider(name) {
      delete sliders[name];
    }

    function prepareCurrencyList(list) {
      return list.map((i) => {
        return { key: i.code, name, icon: i.symbol };
      });
    }

    function getFakeFormData() {
      return {
        login: 'lo' + Date.now(),
        email: 'email' + Date.now() + '@yopmail.com',
        password: 'qweQWE123',
        retype: 'qweQWE123',
        accept: true,
        gdpr_data: true,
        gdpr_marketing: true,
        address: 'address' + Date.now(),
        birthday: '1999-01-01',
        city: 'city',
        name: 'name',
        phone: '+1206456' + Math.round(Date.now() / 1000 / 1000 / 1000),
        surname: 'surname',
        postcode: 'p' + Date.now(),
      };
    }

    function marketing(login, prefix) {
      const uid = prefix + login;
      const mid = _config.mid;

      if (mid) {
        document.querySelector('#wl7starspartners_adsrv_eacdn_com')?.remove();

        const el = document.createElement('iframe');

        el.setAttribute('id', 'wl7starspartners_adsrv_eacdn_com');
        el.setAttribute('style', 'display:none');
        el.setAttribute(
          'src',
          'https://qwfh39851jtfvkurf21hf.com/Processing/Pixels/Registration.ashx?PlayerID=' + uid + '&mid=' + mid
        );

        document.body.appendChild(el);

        $timeout(function () {
          document.querySelector('#wl7starspartners_adsrv_eacdn_com')?.remove();
        }, 2000);
      }

      if ($window.RECONVERT) {
        $window.RECONVERT.registerComplete();
      }
    }

    function setActivePromotionByIndex(index) {
      $timeout(() => {
        this.activePromotion = this.promotions[index];
        this.activePromotionIndex = index;
        this.formData.trigger_codes = this.activePromotion.trigger_codes;
        this.formData.enable_trigger_codes = true;
      });
    }

    function resetActivePromotion() {
      this.activePromotion = {};
      this.formData.enable_trigger_codes = false;
    }

    function setActiveAvatarByIndex(index) {
      $timeout(() => {
        this.activeAvatar = this.avatars[index];
        this.activeAvatarIndex = index;
        this.formData.avatar = this.activeAvatar.name;
      });
    }

    function addPromotionsToScope($scope) {
      if (!!$_REGISTRATION.promotions) {
        $scope.promotions = [];
        $scope.activePromotion = {};
        $scope.activePromotionIndex = null;

        $scope.setActivePromotionByIndex = setActivePromotionByIndex.bind($scope);
        $scope.resetActivePromotion = resetActivePromotion.bind($scope);

        configurePromotionsInScope($scope);
      }
    }

    function addAvatarsToScope($scope) {
      if (!!$_REGISTRATION.avatars) {
        $scope.avatars = [];
        $scope.activeAvatar = {};
        $scope.activeAvatarIndex = null;

        $scope.setActiveAvatarByIndex = setActiveAvatarByIndex.bind($scope);

        configureAvatarsInScope($scope);
      }
    }

    function configurePromotionsInScope($scope) {
      const content = _contentBuffer.read('popup') || _contentBuffer.read('modal') || {};
      $scope.preloader = true;

      _promo
        .list({ category: $_REGISTRATION.promotions.category })
        .then(({ result }) => {
          $scope.promotions = result;

          const activeIndex = getInitialActiveIndex(
            result,
            content.activePromotion || $_REGISTRATION.promotions.active || 0
          );

          $scope.setActivePromotionByIndex(activeIndex);

          const notifySliderConfig = {
            action: eventTypes.initPromotionsSlider,
            initialSlide: activeIndex,
          };

          notifySlider(notifySliderConfig);

          $scope.$watch('formData.page', (newVal) => {
            if (($_REGISTRATION.promotions.page || 0) == newVal) {
              notifySlider({
                action: eventTypes.initPromotionsSlider,
                initialSlide: $scope.activePromotionIndex,
              });
            }
          });
        })
        .catch((error) => console.error(error))
        .finally(() => ($scope.preloader = false));
    }

    function configureAvatarsInScope($scope) {
      const content = _contentBuffer.read('popup') || _contentBuffer.read('modal') || {};
      $scope.preloader = true;

      _system
        .avatars()
        .then(({ result }) => {
          $scope.avatars = result;

          const activeIndex = getInitialActiveIndex(
            result,
            content.activePromotion || $_REGISTRATION.avatars.active || 0
          );

          $scope.setActiveAvatarByIndex(activeIndex);

          const notifySliderConfig = {
            action: eventTypes.initAvatarsSlider,
            initialSlide: activeIndex,
          };

          notifySlider(notifySliderConfig);

          $scope.$watch('formData.page', (newVal) => {
            if (($_REGISTRATION.avatars.page || 0) == newVal) {
              notifySlider(notifySliderConfig);
            }
          });
        })
        .catch((error) => console.error(error))
        .finally(() => ($scope.preloader = false));
    }

    function getInitialActiveIndex(collection, activeConfig) {
      if (angular.isNumber(activeConfig)) {
        return activeConfig;
      }

      const [property, value] = activeConfig;
      return collection.findIndex((item) => item[property] === value);
    }

    function notifySlider(event) {
      events$.next(event);
    }

    return {
      sliders,
      bindSliders,
      registerSlider,
      unregisterSlider,
      events$,
      eventTypes,
      prepareCurrencyList,
      getFakeFormData,
      marketing,
      setActivePromotionByIndex,
      resetActivePromotion,
      setActiveAvatarByIndex,
      addPromotionsToScope,
      addAvatarsToScope,
      config: $_REGISTRATION,
    };
  }

  app.factory('registration', controller);
})();
