import '../domain/tournament.js';
import '../../service/configs/config.js';
import '../../service/domain/state.js';

const factoryName = 'tournamentItemResolver';

class Resolver {
  static $inject = ['$state', 'tournament', 'config', 'state'];

  constructor($state, tournament, config, state) {
    this.tournament = tournament;
    this.state = state;
    this.$state = $state;
    this.config = config;
  }

  gotoNotFound() {
    this.state.goto('notFound', {});
  }

  resolve() {
    const { name } = this.$state.transition.params() || '';
    return this.tournament
      .item({ name })
      .then(({ result }) => result)
      .catch(() => this.gotoNotFound());
  }
}

app.factory(factoryName, Resolver);
