import '../../service/domain/popup.js';
import '../../service/domain/state.js';
(function () {
  'use strict';

  const directive = { name: 'deeplinking' };

  controller.$inject = ['$state', 'popup', 'state', '$interval'];

  function controller($state, _popup, _state, $interval) {
    const link = (scope, element, attrs) => {
      $interval(() => {
        let url = localStorage.getItem('url');

        if (url) {
          url = url.split('/');

          const type = url[2];
          const name = url[3];
          const params = toObject(url[4]);

          switch (type) {
            case 'state':
              _popup.close();
              $state.go(name, params);
              break;
            case 'popup':
              _popup.open({ name: name, content: params });
              break;
            case 'alias':
              _popup.close();
              _state.goto(name);
              break;
            default:
              break;
          }

          localStorage.removeItem('url');
        }
      }, 1000);
    };

    const toObject = function (str) {
      if (!str) return {};
      const rows = str.split('&');
      const r = {};
      rows.map((s) => (r[s.split('=')[0]] = s.split('=')[1]));
      return r;
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
