import '../service/rx/system$.js';
import '../service/domain/documentVerification.js';
import '../service/domain/user.js';
import '../service/localStorage.js';

export const verificationAlert = 'verificationAlert';

class VerificationAlert {
  static $inject = [ 'system$', 'verificationInfo$', 'user', 'localStorage'];
  constructor(_system$, _verificationInfo$, _user, localStorage) {
    this.system$ = _system$;
    this.verificationInfo$ = _verificationInfo$;
    this.user = _user;
    this.localStorage = localStorage;
  }

  shouldShowVerifiedAlert(verificationStatus, alertParams){
    return verificationStatus === 'VERIFIED' && this.localStorage.get(alertParams.localStorageKey);
  }

  updateActiveAlert(verificationStatus, hasOptionalSurvey, alertParams) {
    if (verificationStatus === 'REQUIRES_VERIFICATION') {
      this.localStorage.set(alertParams.localStorageKey, true);
      return verificationStatus;
    }

    if (hasOptionalSurvey) {
      return 'SURVEY';
    }

    if (this.shouldShowVerifiedAlert(verificationStatus, alertParams)) {
      return verificationStatus;
    }

    return null;
  };

  getActiveAlert(alertParams) {
    const verificationStatus = this.user.profile?.verificationStatus;
    const hasOptionalSurvey = this.user.profile?.surveys_status?.has_optional;

    return this.activeAlert = this.updateActiveAlert(verificationStatus, hasOptionalSurvey, alertParams);
  }
}
app.service(verificationAlert, VerificationAlert);
